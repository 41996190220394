

import { Avatar, Button, Col, Empty, List, Result, Row, Spin } from 'antd'
import ProfileName from 'components/ProfileName'
import SideTags from 'components/SideTags'
import FeedCard from 'components/feed/FeedCard'
import Web3Datings from 'container/dating/Web3Datings'
import GroupCard from 'container/group/GroupCard'
import DisplayWall from 'container/socialshop/DisplayWall'
import Web3Sreets from 'container/web3street'
import Utils from 'helper/Utils'
import React from 'react'
import { useState,useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Link, useLocation, useParams } from 'react-router-dom'
import ApiService from 'services/ApiService'
const dataRestros = [
  {
    name:"Bar Pop-up",
    info:"Chiyoda City, Tokyo, Japan",
    link:"https://www.google.com/maps/place/Bar+POP-UP/@35.6924835,139.7677689,17z/data=!3m2!4b1!5s0x60188c022b4ba2df:0x1833ed5245accebf!4m6!3m5!1s0x60188d25df8da33d:0xba8d5f7bd73feb1d!8m2!3d35.6924792!4d139.7703438!16s%2Fg%2F11rk1zn572?entry=ttu",
    icon:"https://cdnspacemarket.com/uploads/attachments/1109050/image.jpg?fit=crop&width=600&height=400&bg-color=9c9c9c"
  },
  {
    name:"Origami Japanese Restaurant",
    info:"Powai, Mumbai, Japan",
    link:"https://www.zomato.com/mumbai/origami-japanese-and-korean-restaurant-powai",
    icon:"https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/sites/7/2018/03/15112025/13032018_origamirestaurant_06.jpg"
  },
  {
    name:"Bar Pop-up",
    info:"Chiyoda City, Tokyo, Japan",
    link:"https://www.google.com/maps/place/Bar+POP-UP/@35.6924835,139.7677689,17z/data=!3m2!4b1!5s0x60188c022b4ba2df:0x1833ed5245accebf!4m6!3m5!1s0x60188d25df8da33d:0xba8d5f7bd73feb1d!8m2!3d35.6924792!4d139.7703438!16s%2Fg%2F11rk1zn572?entry=ttu",
    icon:"https://cdnspacemarket.com/uploads/attachments/1109050/image.jpg?fit=crop&width=600&height=400&bg-color=9c9c9c"
  },
  {
    name:"Origami Japanese Restaurant",
    info:"Powai, Mumbai, Japan",
    link:"https://www.zomato.com/mumbai/origami-japanese-and-korean-restaurant-powai",
    icon:"https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/sites/7/2018/03/15112025/13032018_origamirestaurant_06.jpg"
  },
  {
    name:"Bar Pop-up",
    info:"Chiyoda City, Tokyo, Japan",
    link:"https://www.google.com/maps/place/Bar+POP-UP/@35.6924835,139.7677689,17z/data=!3m2!4b1!5s0x60188c022b4ba2df:0x1833ed5245accebf!4m6!3m5!1s0x60188d25df8da33d:0xba8d5f7bd73feb1d!8m2!3d35.6924792!4d139.7703438!16s%2Fg%2F11rk1zn572?entry=ttu",
    icon:"https://cdnspacemarket.com/uploads/attachments/1109050/image.jpg?fit=crop&width=600&height=400&bg-color=9c9c9c"
  },
  {
    name:"Origami Japanese Restaurant",
    info:"Powai, Mumbai, Japan",
    link:"https://www.zomato.com/mumbai/origami-japanese-and-korean-restaurant-powai",
    icon:"https://imgstaticcontent.lbb.in/lbbnew/wp-content/uploads/sites/7/2018/03/15112025/13032018_origamirestaurant_06.jpg"
  }
]
const SearchResult = (props) => {
    const brParams = useParams();
  const brLocation = useLocation();

    const user = Utils.getLocalInfo("userhash")
    const [loading, setloading] = useState(true);
    const [comingSoon, setcomingSoon] = useState(false)
    const [error, seterror] = useState(null);
    const [feedContent, setfeedContent] = useState([])
    const [groups, setgroups] = useState([])
    const [users, setusers] = useState([])
    const [baseQuery, setbaseQuery] = useState({})
    const [queryParams, setqueryParams] = useState({limit:20,offset:0,sort:"desc"})
    const [srf, setsrf] = useState()
  const [hashTags, sethashTags] = useState([])
  const [pagination, setpagination] = useState({
    offset:0,limit:7,total:0
})
    useEffect(() => {
        const params = brParams;
       
        let filter = {};
        if(params["user"] && params["id"]){
          filter["type"] = "id";
            filter["contentID"] = params["id"]
          }
          else if(params["tag"]){
            filter["type"] = "tag";
            filter["tag"] = params["tag"]
          }
          else if(params["state"]){
            filter["type"] = "tag";
            filter["tag"] =brLocation.search.split("q=")[1]
            setsrf(brLocation.search.split("q=")[1])
          }
          if(params["state"] && ["marketplace","events","places"].includes(params["state"])){
            setloading(false);
            setcomingSoon(true)
            setfeedContent([])
          }
          else{
            setcomingSoon(false);
            setbaseQuery(filter)
            getPosts(filter)
          }
         
      return () => {
        
      }
    }, [brParams,brLocation])
    
    const getPosts = async(filter) =>{
      setloading(true);

      setfeedContent([])
      setgroups([])
      setusers([])
      const queryString = new URLSearchParams(queryParams).toString();
        const res = await ApiService.PostApiCalls(
          `search?${queryString}`,
          {... filter},
          false
        );
        
        if (res[0]) {
          
          if (res[1]["IsSuccessful"]) {
            setfeedContent(res[1]["list"])
            setusers(res[1]["userslist"])
            setgroups(res[1]["groupslist"])
            setloading(false);
            window.scrollTo(0, 0);
          getList();

          } else {
            
            setloading(false);
          getList();

          }
        } else {
          
          setloading(false);
          getList();
        }
    
      }
      const getList = async()=>{
        // setloading(true);
              const members = await ApiService.PostApiCalls("trending/hash",{...pagination,offset:0},true);
              if(members[0] && members[1]["IsSuccessful"]){
            
                sethashTags(members[1]["list"])
                }
                setloading(false);
       }
    // if(user["role"] === "creator" && parseInt(user["creatorProfileStep"]) !== 6){
    //     return(<Redirect to="/creator/edit/1" />)
    // }
    return loading ? (
        <div className="px-4 h-100 d-flex justify-center align-items-center">
          <Spin />
        </div>
      ) :(
        <>
         <Container fluid className='p-0 w-100 mx-0 '>
    <Row gutter={16} className="mx-0">
        {/* LeftFixed */}
        
        <Col span={brParams["state"] === "marketplace" || brParams["state"] === "streets"?24:16} xs={24} sm={24} md={16} lg={18} xl={brParams["state"] === "marketplace" || brParams["state"] === "streets"?24:18} className="mb-3  mx-auto">
        {["top","creators"].includes(brParams["state"]) && users && users.length > 0 &&<div className="px-4 mb-4">
        <List
        className='bg-white p-2 rounded-xl'
       header={<div className='text-bold text-lg'>Creators</div>}
       dataSource={users}
       locale={{emptyText:<div className=' text-hs-pink py-3'><Empty description={`User has no `} /></div>}}
       renderItem={(item) => (
         <List.Item key={item.uniqueId}>
           <List.Item.Meta
             avatar={<Avatar size={46} src={item["appMode"] && item["appMode"] === "privacy"?'https://static.thenounproject.com/png/2910477-200.png':item.profilePicture?item.profilePicture:"https://hs-container.s3.ap-south-1.amazonaws.com/hashapp/campaign-images/images/images/dqdmIww7t3_JCP.png"} />}
             title={<Link to={`/${item.userName}`}><ProfileName classnames={""} appMode={item["appMode"]?item["appMode"]:""} verfied={item["verfied"]} profileName={item.name} /></Link>}
             description={item["appMode"] && item["appMode"] === "privacy"?item.uniqueId:item.userName}
           />
           
         </List.Item>
       )}
     />
        
        
        </div>}
        {["top","groups"].includes(brParams["state"]) && groups && groups.length > 0 &&<div className="px-4 mb-4">
        <List
        className='bg-white p-2 rounded-xl'
       header={<div className='text-bold text-lg'>Communities</div>}
       dataSource={groups}
       locale={{emptyText:<div className=' text-hs-pink py-3'><Empty description={`User has no `} /></div>}}
       renderItem={(item,key) => (
        <GroupCard key={key} post={item} id={key} />
       )}
     />
        
        
        </div>}
        {["top","posts"].includes(brParams["state"]) && feedContent && feedContent.length > 0 &&<div className="px-4">
            {
                feedContent.map((feed,key)=>(
                    <FeedCard key={key} post={feed} id={key} />
                ))
            }
        
        
        </div>}
        {brParams["state"] === "top" && users.length === 0 && groups.length === 0 && feedContent.length === 0 &&<div className="px-4">
        {!comingSoon ?<Result
    status="403"
    title={baseQuery["tag"]?`#${baseQuery["tag"]}`:""}
    subTitle={baseQuery["tag"]?"Sorry, no content found for searched tag.":"Sorry, no post found for searched id."}
    extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
  />:<Result
  status="500"
  title={"Coming Soon"}
  subTitle={""}
  extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
/>}
    
    
    </div>}
    {
     brParams && brParams["state"] === "streets" && 
     <Web3Sreets params={brParams} />
    }
    {
     brParams && ["places"].includes(brParams["state"]) && 
     <div className="px-4">
       <Result
  status="500"
  title={"Coming Soon"}
  subTitle={""}
  extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
/>
    
    
    </div>
    }
    {brParams["state"] === "dating" &&
    <Web3Datings />

  }
  {brParams["state"] === "marketplace" &&
    <DisplayWall filter={srf} />

  }
        {brParams["state"] === "posts" && feedContent && feedContent.length === 0 &&<div className="px-4">
        {!comingSoon ?<Result
    status="403"
    title={baseQuery["tag"]?`#${baseQuery["tag"]}`:""}
    subTitle={baseQuery["tag"]?"Sorry, no post found for searched tag.":"Sorry, no post found for searched id."}
    extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
  />:<Result
  status="500"
  title={"Coming Soon"}
  subTitle={""}
  extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
/>}
    
    
    </div>}
    {brParams["state"] === "creators" && users.length === 0 &&<div className="px-4">
    <Result
    status="403"
    title={baseQuery["tag"]?`#${baseQuery["tag"]}`:""}
    subTitle={baseQuery["tag"]?"Sorry, no creators found for searched tag.":"Sorry, no post found for searched id."}
    extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
  /></div>}
   {brParams["state"] === "groups" && groups.length === 0 &&<div className="px-4">
    <Result
    status="403"
    title={baseQuery["tag"]?`#${baseQuery["tag"]}`:""}
    subTitle={baseQuery["tag"]?"Sorry, no groups found for searched tag.":"Sorry, no post found for searched id."}
    extra={<Link to={"/"}><Button type="primary">Back Home</Button></Link>}
  /></div>}
        </Col>
        {brParams["state"] === "dating" ?
    <Col span={6}> <div section-1 className="rounded-xl bg-white p-2">
    <div className="text-l text-center font-bold mb-2">
        Suggested Restaurants
    </div>
    <div className="py-2"></div>
    
    <List
          dataSource={dataRestros}
          renderItem={(item) => (
            <List.Item key={item.email}>
              <List.Item.Meta
                avatar={<Avatar src={item.icon} />}
                title={<a href={item.link}>{item.name}</a>}
                description={item.info}
              />
              
            </List.Item>
          )}
        />
  </div></Col>
  :
  brParams["state"] === "marketplace" ?
  "":
  brParams["state"] === "streets" ?
  "":
  <Col span={6}> <div section-1 className="rounded-xl bg-white p-2">
        <div className="text-l text-center font-bold mb-2">
        Suggested Trending #
        </div>
        <div className="py-2"></div>
        
        {hashTags.map((d, k) => (
          <SideTags key={k} tag={d} />
        ))}
      </div></Col>

  }
        
        </Row>
            </Container>
       
    </>
    )
}

export default SearchResult
