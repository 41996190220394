import React,{useState,useContext} from 'react'
import {Modal} from "react-bootstrap";
import { Button  } from 'antd';

import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import _ from "lodash"
import Utils from 'helper/Utils';
import ApiService from 'services/ApiService';
import { AuthContext } from 'app/AuthProvider';
import meme1 from "../../../assets/images/meme-temp1.jpg";
import meme2 from "../../../assets/images/meme-temp2.jpg";
import meme3 from "../../../assets/images/meme-temp3.jpg";
import MemeFrame from './components/MemeFrame';
import MemesListing from './components/MemesListing';

const CreateNewMeme = (props) => {
    const [show, setShow] = useState(props.showModalFirst);
    const [buttonOff, setButton] = useState(false)
    const [isProgressing,setProgressing] = useState(false)
    const [isError,setError] = useState(false)
    const {userDetails,activeWorkSpace} = useContext(AuthContext);
    const [memeImage, setmemeImage] = useState(null)
    const { t } = useTranslation();
    const [view, setview] = useState("category")
    const [selectedCat, setselectedCat] = useState(null)
    const [selectedTemp, setselectedTemp] = useState(2)
    const memeTemplates = {
        0:["Text","Image","Text"],
        1:["Image","Text"],
        2:["Text","Image"],
    }
    const categories = [
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d2d65470615e0fc2a79",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763655024adventure.jpg",
            "description": "",
            "name": "Adventure",
            "updatedAt": "2024-12-21T06:47:36.666Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571dba65470615e0fc2a8b",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763922834hiking.jpg",
            "description": "",
            "name": "Hiking",
            "updatedAt": "2024-12-21T06:52:04.885Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571de565470615e0fc2a91",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763881395puppy-4608266_1280.jpg",
            "description": "",
            "name": "Dogs",
            "updatedAt": "2024-12-21T06:51:23.398Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571dee65470615e0fc2a93",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763866979animal.jpg",
            "description": "",
            "name": "Animals",
            "updatedAt": "2024-12-21T06:51:08.554Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e1765470615e0fc2a99",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763816581god-702446_1280.jpg",
            "description": "",
            "name": "God",
            "updatedAt": "2024-12-21T06:50:18.022Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e3965470615e0fc2a9f",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763782187family.jpg",
            "description": "",
            "name": "Family",
            "updatedAt": "2024-12-21T06:49:44.308Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "6757195f65470615e0fc2a73",
            "image": "https://artequitys.s3.amazonaws.com/artequit/user1734763372241Travel.jpg",
            "description": "",
            "name": "Travel",
            "updatedAt": "2024-12-21T06:42:54.872Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d2065470615e0fc2a77",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763995759jogging.jpg",
            "description": "",
            "name": "Fitness",
            "updatedAt": "2024-12-21T06:53:17.777Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d4365470615e0fc2a7b",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763667522concert.jpg",
            "description": "",
            "name": "Concerts",
            "updatedAt": "2024-12-21T06:47:48.980Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d8465470615e0fc2a85",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763898665animal-17760_1280.jpg",
            "description": "",
            "name": "Hunting",
            "updatedAt": "2024-12-21T06:51:40.392Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571dc665470615e0fc2a8d",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763938056camping.jpg",
            "description": "",
            "name": "Camping",
            "updatedAt": "2024-12-21T06:52:19.600Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e0f65470615e0fc2a97",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763835743gym.jpg",
            "description": "",
            "name": "Gym",
            "updatedAt": "2024-12-21T06:50:37.689Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d1265470615e0fc2a75",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763390220food-4936949_1280.jpg",
            "description": "",
            "name": "Food",
            "updatedAt": "2024-12-21T06:43:11.863Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d6265470615e0fc2a7f",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763696086boys-3396713_1280.jpg",
            "description": "",
            "name": "Reading",
            "updatedAt": "2024-12-21T06:48:17.316Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d7865470615e0fc2a83",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763719467movies.jpg",
            "description": "",
            "name": "Movies",
            "updatedAt": "2024-12-21T06:48:41.196Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d9265470615e0fc2a87",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734764035555child-613199_1280.jpg",
            "description": "",
            "name": "Football",
            "updatedAt": "2024-12-21T06:53:57.533Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571df865470615e0fc2a95",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763848491cat.jpg",
            "description": "",
            "name": "Cats",
            "updatedAt": "2024-12-21T06:50:49.992Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e2065470615e0fc2a9b",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763805288pet.jpg",
            "description": "",
            "name": "Pet",
            "updatedAt": "2024-12-21T06:50:07.296Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e2d65470615e0fc2a9d",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763792810spiritual.jpg",
            "description": "",
            "name": "Spirituality",
            "updatedAt": "2024-12-21T06:49:54.102Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571e4365470615e0fc2aa1",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763753385laagh.jpg",
            "description": "",
            "name": "Laugh",
            "updatedAt": "2024-12-21T06:49:14.742Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d5465470615e0fc2a7d",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763679897music.jpg",
            "description": "",
            "name": "Music",
            "updatedAt": "2024-12-21T06:48:01.612Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d6d65470615e0fc2a81",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763708353tatto.jpg",
            "description": "",
            "name": "Tattos",
            "updatedAt": "2024-12-21T06:48:29.844Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571d9e65470615e0fc2a89",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763911593writing.jpg",
            "description": "",
            "name": "Writing",
            "updatedAt": "2024-12-21T06:51:53.154Z"
        },
        {
            "status": 1,
            "is_deleted": 0,
            "_id": "67571dda65470615e0fc2a8f",
            "image": "https://artequitys.s3.ap-northeast-1.amazonaws.com/artequit/user1734763957216comedy.jpg",
            "description": "",
            "name": "Jokes",
            "updatedAt": "2024-12-21T06:52:38.779Z"
        }
    ]
    const user = props.user;
    const handleClose = () => {
        setShow(false)
        props.callback(false);
        localStorage.removeItem("memeframe");
    }
    const handeleNext = () => {
        if(view === "category"){
            setview("memetemplate")
        }
        else if(view === "memetemplate"){
            setview("caption")
        }
    }
    const handeleBack = () => {
        if(view === "memetemplate"){
            setview("category")
        }
        else if(view === "caption"){
            setview("memetemplate")
        }
    }
    const postmeme = ()=>{
        props.postMemeNow()
    }
    const spinIcon =  <div className="anticon anticon-loading anticon-spin ant-spin-dot"></div>;
  return (
    <Modal show={show} onHide={handleClose} centered size={"lg"} contentClassName="campaign-new-modal bg-white">
    <Modal.Header className='h-9' >
        <span className="modal-title h5  font-semibold">{t("Create Meme")}</span>
    </Modal.Header>
    <Modal.Body>
    {
        view === "category" && (
                <><h6>Select One</h6>
             <div className="d-flex align-items-center justify-around flex-row flex-wrap mt-4 h-96 overflow-y-scroll">
               {
                categories.map((category,key) => {
                    return (
                        <div key={key} onClick={()=>setselectedCat(key)} className={selectedCat === key?"cursor-pointer px-2 d-flex align-items-center flex-column justify-content-center ":"cursor-pointer px-2 d-flex align-items-center flex-column justify-content-center "}>
                            <div className="d-flex align-items-center flex-column justify-content-center">
                                <img src={category.image} className={selectedCat === key?"w-24 h-24 rounded-full p-1 bg-red-400":"w-24 h-24 rounded-full p-1"} />
                                <span className={selectedCat === key?"text-red-400 font-semibold text-base  pb-6":"text-gray-70 font-semibold text-base opacity-60 pb-6"}>{category.name}</span>
                            </div>
                        </div>
                    )
                })
               }     
                   
            </div></>

        )
        
    }
    {
        view === "memetemplate" && (
            <MemesListing category={categories[selectedCat]["name"].toLowerCase()} callback={(img)=>setmemeImage(img)} />

    )
    }
    {
        view === "caption" && (
        <><h6>Add Captions</h6>
         <div className="d-flex align-items-center justify-around flex-row space-x-4 flex-wrap mt-4  overflow-y-scroll">
        <MemeFrame memeTemplate={memeTemplates[selectedTemp-1]} memeImage={memeImage} backIt={()=>setview("memetemplate")} postmeme={postmeme} />
        </div></>

    )
    }


    </Modal.Body>
    { view !== "caption" && <Modal.Footer className="mt-4">
    <div className='flex w-full justify-between space-x-4'>
    <div className='flex-1'>
        {view !== "category" &&  <Button variant={"primary"} onClick={handeleBack}>
                { t("Back")}
    </Button>}
    </div>
    <Button variant={"primary"} onClick={handleClose}>
                { t("Close")}
    </Button>
 
    <Button type="primary" onClick={handeleNext} disabled={selectedCat === null} >
    {t("Next")}
    </Button>
    </div>
    </Modal.Footer>}
    </Modal>
    
  )
}

export default CreateNewMeme