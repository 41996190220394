import * as React from "react"
const BluLogo = (props) => (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={148.284}
    height={40.134}
    {...props}
  >
    <g fillRule="evenodd" data-name="Group 1">
      <path
        fill="#002f6d"
        d="M13.378 13.378a13 13 0 0 0-6.688 1.823V3.638A3.381 3.381 0 0 0 3.319.267a3.186 3.186 0 0 0-2.328.99 3.246 3.246 0 0 0-.99 2.381v33.178a3.382 3.382 0 0 0 3.318 3.318h10.06a13.378 13.378 0 1 0 0-26.756Zm0 20.067a6.684 6.684 0 1 1 4.736-1.962 6.424 6.424 0 0 1-4.736 1.962Z"
        data-name="Path 1"
      />
      <path
        fill="#002f6d"
        d="M33.82 0a3.382 3.382 0 0 0-3.318 3.318v33.445a3.382 3.382 0 0 0 3.318 3.318 3.247 3.247 0 0 0 2.381-.99 3.186 3.186 0 0 0 .99-2.328V3.318a3.186 3.186 0 0 0-.99-2.328A3.247 3.247 0 0 0 33.82 0Z"
        data-name="Path 2"
      />
      <path
        fill="#002f6d"
        d="M64.643 13.378a3.382 3.382 0 0 0-3.318 3.318v10.113a6.318 6.318 0 0 1-1.98 4.709 6.521 6.521 0 0 1-4.709 1.927 6.431 6.431 0 0 1-4.682-1.927 6.549 6.549 0 0 1-2.007-4.709V16.696a3.186 3.186 0 0 0-.99-2.328 3.247 3.247 0 0 0-2.381-.99 3.382 3.382 0 0 0-3.318 3.318v10.382a13.036 13.036 0 0 0 4.04 9.257 12.865 12.865 0 0 0 9.338 3.8h10.007a3.246 3.246 0 0 0 2.381-.99 3.185 3.185 0 0 0 .99-2.328V16.696a3.186 3.186 0 0 0-.99-2.328 3.246 3.246 0 0 0-2.381-.99Z"
        data-name="Path 3"
      />
      <path
        fill="#989898"
        d="M117.513 26.435a13.037 13.037 0 0 0-4.04-9.258 13.389 13.389 0 0 0-19.4.749 12.7 12.7 0 0 0-10.007-4.548h-10.06a3.382 3.382 0 0 0-3.318 3.318v20.123a3.382 3.382 0 0 0 3.318 3.318 3.246 3.246 0 0 0 2.381-.99 3.185 3.185 0 0 0 .99-2.328V26.702a6.55 6.55 0 0 1 2.007-4.709 6.653 6.653 0 0 1 9.365 0 6.55 6.55 0 0 1 2.007 4.709v10.117a3.382 3.382 0 0 0 3.318 3.318h.054a3.382 3.382 0 0 0 3.318-3.318V26.702a6.6 6.6 0 0 1 1.98-4.709 6.686 6.686 0 0 1 9.392 0 6.55 6.55 0 0 1 2.007 4.709v10.117a3.382 3.382 0 0 0 3.318 3.318 3.247 3.247 0 0 0 2.381-.99 3.187 3.187 0 0 0 .99-2.328Z"
        data-name="Path 4"
      />
      <path
        fill="#989898"
        d="M148.286 26.756h-.054a3.1 3.1 0 0 0-1.106-2.033 3.275 3.275 0 0 0-2.185-.8 3.347 3.347 0 0 0-2.238.8 3.1 3.1 0 0 0-1.106 2.033 6.669 6.669 0 0 1-6.689 6.689 6.765 6.765 0 0 1-2.141-.375 3.905 3.905 0 0 0 .7-.535l10.7-10.7a3.323 3.323 0 0 0 .214-4.442h.053l-.428-.428a13.349 13.349 0 1 0 4.281 10.114v-.321Zm-13.378-6.743a6.89 6.89 0 0 1 1.445.16l-7.6 7.652a1.575 1.575 0 0 0-.375.428 7.752 7.752 0 0 1-.161-1.507 6.53 6.53 0 0 1 1.953-4.772 6.423 6.423 0 0 1 4.735-1.96Z"
        data-name="Path 5"
      />
    </g>
  </svg>
)
export default BluLogo
