import { Button, Form, Input, Menu, message } from "antd";
import Utils from "helper/Utils";
import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "app/AuthProvider";
import {
  BookmarkHeart,
  CalendarWeek,
  CardChecklist,
  ChatRightText,
  Gear,
  GraphUp,
  Hash,
  Heart,
  House,
  ListStars,
  People,
  Share,
  Sliders,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import WorkspacesDropdown from "./WorkspacesDropdown";
import { LiaUserTagSolid } from "react-icons/lia";
import { VscVmConnect } from "react-icons/vsc";
import axios from "axios";
import ApiService from "services/ApiService";
const SidebarCreator = (props) => {
  const { t } = useTranslation();
  
  const { activeWorkSpace, authUser} = useContext(AuthContext);
  
  const [activeSlug, setactiveSlug] = useState(activeWorkSpace["slug"])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOption, setmodalOption] = useState("share");
  const [sendingAppMail, setsendingAppMail] = useState(false)
  useEffect(() => {
    setactiveSlug(activeWorkSpace["slug"])
    return () => {};
  }, [activeWorkSpace]);
  
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setmodalOption("share");
  };
  const getApp = async() =>{
    setmodalOption("app");
    showModal()
  }
  const onFinish = async(values)=>{
    
    setsendingAppMail(true)
    const emailed = await ApiService.PostApiCalls("general/download/app",{...values})
    message.success("Email has been sent successfully.");
    handleCancel();
    setsendingAppMail(false)

    
  }
  const onFinishFailed = (errorInfo) => {
    
  };
  return (
    <>
    <div className="relative">
      <div section-1 className="h-screen bg-white p-2">
        <div className="mb-2">
          <WorkspacesDropdown authUser={authUser} activeWorkSpace={activeWorkSpace} hide={props.collapsed} /></div>
        <Menu className="border-0 active " selectedKeys={props.sidebarKey}>
          <Menu.Item
            key={"0"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<House size={18} />}
          >
            <Link to={"/creator/home/"+activeSlug} className="text-hl">
              {t("Dashboard")}
            </Link>
          </Menu.Item>

          <Menu.Item
            key={"1"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<BookmarkHeart size={18} />}
          >
            <Link to={`/creator/${activeSlug}/campaigns`} className="text-hl">
              {" "}
              {t("Campaigns")}
              
            </Link>
          </Menu.Item>
          {/* {authUser.isAdmin && <Menu.Item
            key={"5"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Hash size={18} />}
          ><Link to={`/creator/hashmine`} className="text-hl">
              {t("Hash Mine")}
            </Link>
          </Menu.Item>} */}
         
          <Menu.Item
            key={"5"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Hash size={18} />}
          ><Link to={`/creator/hashmine`} className="text-hl">
              {t("Hash Mine")}
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"2"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<GraphUp size={18} />}
          >
            <Link to={`/creator/${activeSlug}/analytics`} className="text-hl">
            {t("Analytics")}
              
            </Link>
          </Menu.Item>
          <Menu.Item
            key={"3"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<CalendarWeek size={18} />}
          >
            <Link to={`/creator/${activeSlug}/calendar`} className="text-hl">
              {" "}
              {t("Calendar")}
              
            </Link>
          </Menu.Item>
          {authUser.selectedAppPlan.id === 5 && <Menu.Item
            key={"8"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<LiaUserTagSolid size={18} />}
          ><Link to={`/creator/social-commerce/content-gallery`} className="text-hl">
              Social Commerce
            </Link>
          </Menu.Item>}
          <Menu.Item
            key={"4"}
            className="text-hl flex flex-row items-center border-l-2 "
            icon={<Gear size={18} />}
          >
            <Link to={`/creator/${activeSlug}/settings/campaigns`} className="text-hl">
              {" "}
              {t("Settings")}
              
            </Link>
          </Menu.Item>
          <Menu.Item className="hidden md:block">
          <Button onClick={getApp}  className='rounded mr-3 bg-hs-blue  border-hs-dark' type='primary' >Download BluMe App</Button>
          </Menu.Item>
        </Menu>
       
      </div>
    
    </div>
    <Modal show={isModalOpen} onHide={handleCancel} dialogClassName="modal-25w">
    <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body className="p-0">
  {modalOption === "share"? <p></p>:
   <p className="p-3">
    <span className="font-semibold text-lg">Download BluMe android app to earn HASHS coins for every second of activity you do on HASHSAGA Ecosystem. Share email to receive download link.</span>
   <span>
   <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
 <Form.Item
                  label={t("Email")}
                  className="py-2"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Button
                    type="primary"
                    loading={sendingAppMail }
                    htmlType="submit"
                    className="px-2 rounded-xl"
                  >
                    {t("Get App Link")}
                  </Button>
                </Form.Item>
    </Form>
   </span>
   </p>
   
   }
   </Modal.Body>
 </Modal></>
  );
};

export default SidebarCreator;
