import { Button, Result, Spin, Row } from "antd";
import FeedCard from "components/feed/FeedCard";
import Utils from "helper/Utils";
import React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ApiService from "services/ApiService";
import MarketCard from "./MarketCard";

const MarketPlace = (props) => {
  const navigate = useNavigate()
  const user = Utils.getLocalInfo("userhash");
  const [loading, setloading] = useState(true);
  const [error, seterror] = useState(null);

  const [groups, setgroups] = useState([]);
  const [baseQuery, setbaseQuery] = useState({});
  const [queryParams, setqueryParams] = useState({
    limit: 20,
    offset: 0,
    sort: "desc",
  });
  const grps = [
    {
      name: "₹350",
      members: "Cotton Solid Half Sleeves Polo T-Shirts",
      image:
        "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/G1avbBFjMh_yiN.png"
    },
    {
        name: "₹350",
        members: "Cotton shirt ( cash on delivery)",
      image:
       "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/4MaltCF34g_4bt.jpeg"
    },
    {
      name: "₹560",
      members: "Gown Floral ( cash on delivery)",
      image:
       "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/GqCkb6ejq4_u55.png"
    },
    {
      name: "₹699",
      members: "boAt Airdopes 131 & 138 Bluetooth Headset",
      image:
       "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/Jhf6A9AOcA_xqO.jpeg"
    },
    {
      name: "₹6,000",
      members: "FULL DESKTOP SET FOR HOME USE (MUMBAI)",
      image:
        "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/rbkfvFMyEA_3JD.png"
    },

    {
        name: "₹1,000",
        members: "Exclusive Collection of Blocks and Bricks",
        image:
        "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/jf3LMHdAkc_vqC.jpeg"
      },

    {
        name: "₹40,000",
        members: "Good as New Drumset with shipping",
        image:
         "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/Ewxa95oexi_F7j.jpeg"
      },

    {
        name: "₹180",
        members: "Fanduster folding nd light weight",
        image:
          "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/v23qn9gbfl_K5M.jpeg"
      },

    {
        name: "₹21",
        members: "Mini Fan- USB Chargeable Fan With Ears",
        image:
          "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/Idaih3C5eh_Nv4.jpeg"
      },
      
  ];
  useEffect(() => {
    const params = props["match"]["params"];
    setgroups(grps);
    setloading(false);
    // let filter = {};
    // if (params["user"] && params["id"]) {
    //   filter["type"] = "id";
    //   filter["contentID"] = params["id"];
    // } else if (params["tag"]) {
    //   filter["type"] = "tag";
    //   filter["tag"] = params["tag"];
    // }
    // setbaseQuery(filter);
    // getPosts(filter);
    return () => {};
  }, [props]);

  const getPosts = async (filter) => {
    const queryString = new URLSearchParams(queryParams).toString();
    const res = await ApiService.PostApiCalls(
      `posts?${queryString}`,
      { ...filter },
      true
    );

    if (res[0]) {
      if (res[1]["IsSuccessful"]) {
        setgroups(res[1]["list"]);
        setloading(false);
        window.scrollTo(0, 0);
      } else {
        setloading(false);
      }
    } else {
      setloading(false);
    }
  };
  if (
    user["role"] === "creator" &&
    parseInt(user["creatorProfileStep"]) !== 5
  ) {
    return navigate("/creator/edit/1" ) ;//<Redirect to="/creator/edit/1" />;
  }

  return loading ? (
    <div className=" h-100 d-flex justify-center align-items-center bg-white py-3 px-4 rounded-xl ">
      <Spin />
    </div>
  ) : (
    <>
      <div className=" d-flex justify-center flex-col bg-white py-3 rounded-xl ">
        <div className="px-4 d-flex justify-between mb-2 pb-2 border-b">
          <span className="text-hp text-lg font-semibold">Featured Items</span>
          {/* <Button className="rounded-sm ">Create Community</Button> */}
        </div>
        {groups && groups.length > 0 && (
          <div className="px-4 space-y-3">
            <Row gutter={16} >
              {groups.map((feed, key) => (
                <MarketCard key={key} post={feed} id={key} />
              ))}
            </Row>
          </div>
        )}
        {groups && groups.length === 0 && (
          <div className="px-4 bg-white">
            <Result
              status="403"
              title={baseQuery["tag"] ? `#${baseQuery["tag"]}` : ""}
              subTitle={
                baseQuery["tag"]
                  ? "Sorry, no post found for searched tag."
                  : "Sorry, no post found for searched id."
              }
              extra={
                <Link to={"/"}>
                  <Button type="primary">Back Home</Button>
                </Link>
              }
            />
          </div>
        )}
      </div>
    </>
  );
};

export default MarketPlace;
