import { Button } from "antd";
import CardVisual from "components/feed/CardVisual";
import SocialIcon from "container/common/SocialIcon";
import moment from "moment";
import React from "react";
import { CalendarEvent } from "react-bootstrap-icons";

const CampaignPostCard = ({ content, id,type }) => {
  return (
    <div className="cursor-pointer">
      <CardVisual visual={content.visual} type={content.type} />
      <div className="py-2"></div>
      <div className="px-1 line-clamp-2 font-normal text-h text-sm">
        {content.content}
      </div>
      <div className="py-1"></div>
      <div className="px-2 line-clamp-2 font-normal text-xs text-blue-500">
        https://hsh.sh/7aystw
      </div>
      <div className="py-1"></div>
      <div className="px-2 font-normal text-xs text-h flex flex-row items-center">
        <CalendarEvent />
        <span className="ml-2">
          {moment.unix(moment().unix()).format("MMM Do, hh:mm a")}
        </span>
      </div>
      <div className="py-2"></div>
      {type === 0 ?<div className="px-2 flex flex-row space-x-2 items-center">
        <SocialIcon icon="instagram" />
        <SocialIcon icon="facebook" />
        <SocialIcon icon="twitter" />
        <SocialIcon icon="linkedin" />
      </div>:<div className="px-2 flex flex-row space-x-2 items-center">
        <Button type="primary" block className="rounded-xl">Review</Button>
      </div>}
      <div className="py-1"></div>
    </div>
  );
};

export default CampaignPostCard;
