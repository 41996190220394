import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Button, Checkbox, Divider,Space } from "antd";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { GoogleLogin } from "react-google-login";
import "./style.css";
import { BsXCircle } from "react-icons/bs";
import ApiService from "services/ApiService";
import Utils from "helper/Utils";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import _, { values } from "lodash";
import { XCircleFill } from "react-bootstrap-icons";
import { useTranslation } from 'react-i18next';
import { loadStripe } from "@stripe/stripe-js";
import {useStripe, useElements, Elements,CardElement} from '@stripe/react-stripe-js';
import PaymentCard from "container/common/PaymentCard";

const AuthModal = ({ isVisible, cancelCallback, creatorCallback,billingCycle, type,isWhite,planId,upgradedPlan,authUser }) => {
  const { t } = useTranslation();
  const appLocale = localStorage.getItem("appLocale")
  let setDefault = "en";
  if(appLocale){
    setDefault = appLocale
  }
  // const stripePromise = loadStripe("pk_test_51LDMFTSCziW5a8WnahZL7oy2kpBCIdxFQVcwC1U0yDU7Ak7J0aclUaVHAlqsMncC5fDl6WyLmFiuq02j5pW9Gwv800V30n2tKD",{locale: setDefault});
  const stripePromise = loadStripe("pk_live_51LDMFTSCziW5a8WnrdxtE3x0EZ4Ls0AWMSG81PiHJEpekq14XMP4kA0LIlriDJYXy2e1JjubKETbDgfSTcuEnA1Z00SL3BpanT",{locale: setDefault});
  const [form] = Form.useForm();
  const [isModalVisible, setisModalVisible] = useState(isVisible);
  const [isSignUp, setisSignUp] = useState(false);
  const [isResetPassword, setisResetPassword] = useState(false);
  const [thankYou, setthankYou] = useState(null)
  const [isSaving,setSaving] = useState(false)  

  useEffect(() => {
    setisModalVisible(isVisible);
    form.resetFields();
    return () => {
      setisModalVisible(false);
    };
  }, [isVisible]); 
  const handleOk = () => {
    setisModalVisible(false); 
    setthankYou(null)
    form.resetFields();
  };
 
  const handleCancel = () => {
    setisModalVisible(false);
    setisSignUp(false);
    setisResetPassword(false);
    form.resetFields();
    setthankYou(null)

    cancelCallback();
  };
  const checkSocialConnect = async (data) => {
    const endPoint = "account/socialconnect";

    const response = await ApiService.PostApiCalls(endPoint, data);
    
    if (response[0]) {
      
      const serverRespons = response[1];
      if (serverRespons["IsSuccessful"]) {
        Utils.processAuthIn( serverRespons["user"]);
        Utils.showNotification(
          "success",
          "Done",
          serverRespons["FriendlyMessage"]
        );
        window.location.reload();
      } else {
        Utils.showNotification(
          "error",
          "Oooops",
          serverRespons["ErrorMessage"]
        );
      }
    } else {
      Utils.showNotification(
        "error",
        "Oooops",
        "Error while processing request"
      );
    }
  };
  const onFinish = async (values) => {
    const endPoint = isSignUp
      ? "account/register"
      : isResetPassword
      ? "account/resetpassword"
      : "account/login";

    const modein = localStorage.getItem("appUsageMode")
    const formContent = {
      role: type.toLowerCase(),
      name: values.name,
      email: values.loginemail.toLowerCase(),
      rememberSession: values.remember,
      password: values.loginpassword,
      accountSource: "Email",
      appMode:modein?modein:"earning"
    };
    const response = await ApiService.PostApiCalls(endPoint, formContent);
    if (response[0]) {
      const serverRespons = response[1];
      if (serverRespons["IsSuccessful"]) {
        Utils.processAuthIn( serverRespons["user"]);
        Utils.showNotification(
          "success",
          "Done",
          serverRespons["FriendlyMessage"]
        );
        if(isSignUp){
          // creatorCallback();
          window.location.reload();
        }else{window.location.reload();}
        
      } else {
        Utils.showNotification(
          "error",
          "Oooops",
          serverRespons["ErrorMessage"]
        );
      }
    } else {
      Utils.showNotification(
        "error",
        "Oooops",
        "Error while processing request"
      );
    }
  };
  const onFinishWait = async(values)=>{
   

    
    const formContent = {
      
      name: values.waitname,
      email: values.waitemail.toLowerCase(),
      mobile: values.waitmobile,
      country: values.waitcountry,
      socialLinks:[values.socialLinkAdded,...values["socialLinks"]],
      planeId:planId
    };
   
    setthankYou("Sending request!")
    const endPoint = "subscriptions/addwaitlist"
    const response = await ApiService.PostApiCalls(endPoint, formContent);
    if (response[0]) {
      const serverRespons = response[1];
      
      if (serverRespons["IsSuccessful"]) {
        form.resetFields();
        setthankYou("You are in waiting list, we'll confirm soon. Thank you!")
      }
      else{
        setthankYou(null)
        Utils.showNotification(
          "error",
          "Oooops",
          serverRespons["ErrorMessage"]
        );
      } 

    }else{
      setthankYou(null)
      Utils.showNotification(
        "error",
        "Oooops",
        "Error while processing request"
      );
    }

  }
  const responseFacebook = async (profileObj) => {
   
    let data = _.pick(profileObj, ["email", "name", "picture","userID"]);
    data = {
      ...data,
      profilePicture: data.picture["data"]["url"],
      role: type.toLowerCase(),
      accountSource: "Facebook",
      isSocial: true,
    };
    if (_.get(data, "email", false)) {
      checkSocialConnect(data);
    } else {
      if (_.get(data, "userID", false)) {
      data = {
        ...data,
        email:data["userID"]+"@facebook.com",
        profilePicture: data.picture["data"]["url"],
        role: type.toLowerCase(),
        accountSource: "Facebook",
        isSocial: true,
      };
      checkSocialConnect(data);
    }
     else{
      Utils.showNotification(
        "error",
        "Oooops",
        "Error while processing request"
      );
    }
    }
  };
  const responseGoogle = async ({ profileObj }) => {
    let data = _.pick(profileObj, ["email", "name", "imageUrl"]);
    data = {
      ...data,
      profilePicture: data.imageUrl,
      role: type.toLowerCase(),
      accountSource: "Google",
      isSocial: true,
    };
    if (_.get(data, "email", false)) {
      checkSocialConnect(data);
    } else {
      Utils.showNotification(
        "error",
        "Oooops",
        "Error while processing request"
      );
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const responseGoogleFailed = () => {};
  const paymentCallback = async(card,paymentMethodId=null,cardName,cpData=null,customerId=null) =>{
    //CreatePaymantAccountIfNewCustomer
    if(paymentMethodId){
      const cmt = await ApiService.PostApiCalls("payment/create",{'payment_method': paymentMethodId, cardName:cardName,'email': authUser.email,customerId:customerId})
      console.log(cmt);
    }
    console.log(cpData);
  }
  const updateSubscription = async () =>{
    setSaving(true)
    const subscriptionDetails = {
      newPlanId: billingCycle==="monthly"?upgradedPlan.price["stripe_product_id_m"]:upgradedPlan.price["stripe_product_id_y"]
    }
    const saved = await ApiService.PostApiCalls("payment/updateSubscription",subscriptionDetails,true);
    if(saved[0] && saved[1]["IsSuccessful"]){
      const resultsub = saved[1]["data"];
   
      const newsubscriptionDetails = {
        update: {planId:upgradedPlan["id"],
        code:upgradedPlan["code"],
        duration:billingCycle,
        email:authUser["email"],
        subId:resultsub["id"],
        customer:resultsub["customer"],
        plan:resultsub["plan"],
        collection_method:resultsub["collection_method"],
        current_period_start:resultsub["current_period_start"],
        current_period_end:resultsub["current_period_end"],
        latest_invoice:resultsub["latest_invoice"],
        status:resultsub["status"],
        created:resultsub["created"]},selectedAppPlan:{code:upgradedPlan["code"],id:upgradedPlan["id"],duration:"monthly"}
    }
      const UpdateSub = await ApiService.PostApiCalls("payment/changeSubscription",newsubscriptionDetails,true);
      if(UpdateSub[0] && UpdateSub[1]["IsSuccessful"]){
        Utils.showNotification(
          "success",
          "Done",
          "Your ubscription has been updated."
        );
        handleCancel()
      }
      else{
        Utils.showNotification(
          "error",
          "Oops",
          "Something went wrong. Try again"
        );
       
      }
  }else{
    Utils.showNotification(
      "error",
      "Oops",
      "Something went wrong. Try again"
    );
  }
    setSaving(false)


  }
  return (
    <>
    {type === "wait"
    ?
    <Modal
    className={isWhite?"modalAuthS pb-0 mb-2 px-0 rounded-xl overflow-hidden":"modalAuth overflow-hidden rounded-xl text-white w-50"}
    style={{ top: 20 }}
    footer={null}
    closeIcon={<BsXCircle size={20}  className={isWhite?"absolute right-3 top-3 text-red":"absolute right-3 top-3 text-hs-pink"} />}
    visible={isModalVisible}
    onOk={handleOk}
    onCancel={handleCancel}
    bodyStyle={{padding:0}}
    title="Please share details as mentioned below."
    width={600}
    // mask={mask}
    
  >
   
      <div className="flex flex-col space-y-2 px-4 ">
       <div className="py-2 w-100"></div>
       
          <div className="text-center bold text-base tracking-wide text-white mt-2">
           {!thankYou && <Form
              name="basic"
              layout="vertical"
              wrapperCol={{ span: 24 }}
              form={form}
              initialValues={{ remember: true }}
              onFinish={onFinishWait}
              onFinishFailed={onFinishFailed}
              className="text-white"
            >
               <Form.Item
                 wrapperCol={{ offset: 0, span: 24 }}
                label="Name"
                
                className="py-0 font-semibold"
                name="waitname"
                rules={[
                  { required: true, message: "Enter name!" },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder={type}
                />
              </Form.Item>
              <Form.Item
               wrapperCol={{ offset: 0, span: 24 }}
                label="Email"
                className="py-0 font-semibold"
                name="waitemail"
                rules={[
                  { required: true,type: "email", message: "A valid email is missing!" },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder="your@email.com"
                />
              </Form.Item>

              <Form.Item
               wrapperCol={{ offset: 0, span: 24 }}
                label="Mobile"
                className="py-0 font-semibold"
                name="waitmobile"
                rules={[
                  { required: false, message: "", max:12 },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder="Mobile number"
                />
              </Form.Item>

              <Form.Item
               wrapperCol={{ offset: 0, span: 24 }}
                label="Country"
                className="py-0 font-semibold"
                name="waitcountry"
                rules={[
                  { required: true, message: "Enter country name!", },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder="Country"
                />
              </Form.Item>
              <Form.Item
               wrapperCol={{ offset: 0, span: 24 }}
                label="Social Profile (Facebook, Twitter, Instagram, Linkedin, Youtube etc.)"
                className="py-0 font-semibold"
                name="socialLinkAdded"
                rules={[
                  { required: true,type:"url", message: "Enter valid link!", },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder="Social Profile Link"
                />
              </Form.Item>
              <Form.List name="socialLinks" >
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ key, name, ...restField }) => (
                <div className="flex items-center mb-3 justify-between">
                <Form.Item
                  className="flex-grow py-0 font-semibold mr-1 mb-0"
                  name={[name]}
                  
                  rules={[
                    { required: true,type:"url", message: "Enter valid link!", },
                  ]}
                >
                   <Input
                  className="p-2 rounded-md"
                  placeholder="Social Profile Link"
                />
                </Form.Item>
                <BsXCircle  color="#f6327d" size={20} onClick={() => remove(name)}/>
                
                </div>
                
              //   <MinusCircleOutlined onClick={() => remove(name)} />
                
              // </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Social Link
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
              {/* <Form.Item
               wrapperCol={{ offset: 0, span: 24 }}
                label="Social Profile "
                className="py-0"
                name="waitslink2"
                rules={[
                  { required: false,type:"url", message: "Enter valid link!", },
                ]}
              >
                <Input
                  className="p-2 rounded-md"
                  placeholder="Social Profile Link"
                />
              </Form.Item> */}
              
              {!thankYou && <Form.Item wrapperCol={{ offset: 20, span: 4 }}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  className="px-2 rounded-xl"
                >
                  Submit
                </Button>
              </Form.Item>}
              {thankYou && <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <span>{thankYou}</span>
              </Form.Item>}
            </Form>}
            {thankYou && <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="py-3">
                <span className="py-5 text-left text-border text-base tracking-wide text-hs-dark">{thankYou}</span>
              </Form.Item>}
          </div>
         
            
        
        
       
       
      </div>
    
  </Modal>:
  type === "upgrade"
  ?
  <Modal
  className={isWhite?"modalAuthS pb-0 mb-2 px-0 rounded-xl overflow-hidden":"modalAuth overflow-hidden rounded-xl text-white w-50"}
  style={{ top: 20 }}
  footer={null}
  closeIcon={<BsXCircle size={20}  className={isWhite?"absolute right-3 top-3 text-red":"absolute right-3 top-3 text-hs-pink"} />}
  visible={isModalVisible}
  onOk={handleOk}
  onCancel={handleCancel}
  bodyStyle={{padding:0}}
  title="Upgrade Plan"
  width={600}
  // mask={mask}
  
>
 
    <div className="flex flex-col space-y-2 px-4 ">
     <div className="py-2 w-100"></div>
     
        <div className="text-left bold text-base tracking-wide mt-2">
        {
          !thankYou && <>
          <span className="text-left text-gray-600">
            {`You are upgrading plan from ${authUser["selectedAppPlan"]["code"].toUpperCase()} to ${upgradedPlan["code"].toUpperCase()}. You will be charged `}<span className="font-bold">${`${upgradedPlan["price"][billingCycle]}`}</span> per {billingCycle==="monthly"?"month":"year"}.</span>
            <div className="py-2 w-100"></div>
     <div className="p-2 w-100">
     {
      authUser.paymentInfo  ? <div>
         <p className="fz-16 pb-3">
                <span className="ext-capitalize">{`Current card on file is a`} <span className="text-capitalize font-bold">{`${authUser["paymentInfo"]["brand"]} card`}</span>{` ending in `}<span className=" font-bold text-purple-800">{authUser["paymentInfo"]["last_four"]}</span>{`.`}</span>
                </p>
      </div>:
      
      <Elements  stripe={stripePromise} ><PaymentCard user={authUser} paymentCallback={paymentCallback}/></Elements>}
     </div>
    {authUser.paymentInfo  && <div className="py-2 w-100 flex justify-end space-x-4"> <Button key="back" disabled={isSaving} className="brd-4 blueline-btn" onClick={handleCancel}>
                Cancel
              </Button>
              <Button key="next" loading={isSaving}  type="primary" className="brd-4 " onClick={updateSubscription}  >
              Confirm Upgrade
            </Button></div>}

          </>
        }
     <div className="py-2 w-100"></div>

          {thankYou && <Form.Item wrapperCol={{ offset: 0, span: 24 }} className="py-3">
              <span className="py-5 text-left text-border text-base tracking-wide text-hs-dark">{thankYou}</span>
            </Form.Item>}
        </div>
       
          
      
      
     
     
    </div>
  
</Modal>:
    <Modal
      className={isWhite?"modalAuthS pb-0 mb-2 rounded-xl overflow-hidden w-96":"modalAuth overflow-hidden rounded-xl text-white w-96"}
      style={{ top: 20 }}
      footer={null}
      closeIcon={<BsXCircle className={isWhite?"absolute right-3 top-3 ":"absolute -right-3 -top-3 text-white"} />}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      // mask={mask}
      
    >
      {isResetPassword ? (
        <div className="flex flex-col space-y-2 px-8 ">
          <div className="text-center bold text-base tracking-wide">
            Reset password with email
          </div>

          <div className="text-center bold text-base tracking-wide text-white">
            <Form
              name="basic"
              layout="vertical"
              wrapperCol={{ span: 24 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="text-white"
            >
              <Form.Item
                label="Email"
                className="py-2"
                name="loginemail"
                rules={[
                  { required: true, message: "A valid email is missing!" },
                ]}
              >
                <Input
                  className="p-2 rounded-xl"
                  placeholder="your@email.com"
                />
              </Form.Item>

              <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                <Button
                  type="primary"
                  block
                  htmlType="submit"
                  className="px-2 rounded-xl"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>

          <div className="text-center font-light text-xs tracking-wide text-white pt-2"></div>
          <div className="text-center font-light text-xs tracking-wide text-white">
            <Divider className="text-white font-light text-xs cursor-pointer">
              <Button
                onClick={() => setisResetPassword(false)}
                type="link"
                block
                className="px-2 rounded-xl "
              >
                Sign In
              </Button>
            </Divider>
          </div>
        </div>
      ) : (
        <div className="flex flex-col space-y-2 px-8 ">
          <div className="text-center bold text-base tracking-wide">
            {!isSignUp ? `${t("authHeader")} ${t(type)}` : `${t("authheader1")} ${t(type)}`}
          </div>
          {isSignUp ? (
            <div className="text-center bold text-base tracking-wide text-white">
              <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
                <Form.Item
                  label="Name"
                  className="py-1"
                  name="name"
                  rules={[{ required: true, message: "Name is missing!" }]}
                >
                  <Input className="p-2 rounded-xl" placeholder="Name" />
                </Form.Item>
                <Form.Item
                  label="Email"
                  className="py-1"
                  name="loginemail"
                  rules={[
                    { required: true, message: "A valid email is missing!" },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="loginpassword"
                  rules={[
                    { required: true, message: "Password can't be empty!" },
                  ]}
                >
                  <Input.Password
                    autoComplete="false-none"
                    className="p-2 rounded-xl"
                    placeholder="*********"
                  />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="px-2 rounded-xl"
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            </div>
          ) : (
            <div className="text-center bold text-base tracking-wide text-white">
              <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
                <Form.Item
                  label={t("Email")}
                  className="py-2"
                  name="loginemail"
                  rules={[
                    { required: true, message: t("EmailValidation") },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>

                <Form.Item
                  label={t("Password")}
                  name="loginpassword"
                  rules={[
                    { required: true, message: t("PasswrodValidation") },
                  ]}
                >
                  <Input.Password
                    autoComplete="false-none"
                    className="p-2 rounded-xl"
                    placeholder="*********"
                  />
                </Form.Item>

                <Form.Item
                  name="remember"
                  valuePropName="checked"
                  className="text-left text-white justify-between"
                >
                  <Checkbox className={isWhite?" text-xs":"text-white text-xs"}>
                  {t("Remember me")}
                  </Checkbox>
                  <Button
                    type="link"
                    htmlType="button"
                    className="text-xs self-end"
                    onClick={() => setisResetPassword(true)}
                  >
                   {t("PasswordReset")}
                  </Button>
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Button
                    type="primary"
                    block
                    htmlType="submit"
                    className="px-2 rounded-xl"
                  >
                    {t("Submit")}
                  </Button>
                </Form.Item>
              </Form>
            </div>
          )}{" "}
          <div className="text-center font-light text-xs tracking-wide text-white">
            <Divider className={isWhite?" font-light text-xs":"text-white font-light text-xs"} >
              Or Continue With
            </Divider>
          </div>
          <div className="text-center font-light text-xs tracking-wide text-white">
            <FacebookLogin
              appId={"1438247273203808"}
              autoLoad={false}
              fields="name,email,picture"
              render={(renderProps) => (
                <Button
                  type="primary"
                  onClick={renderProps.onClick}
                  block
                  className="px-2 rounded-xl"
                >
                  Sign in with Facebook
                </Button>
              )}
              callback={responseFacebook}
            />
          </div>
          <div className="text-center font-light text-xs tracking-wide text-white pt-2">
            <GoogleLogin
              clientId={process.env.REACT_APP_GOOGLE_APP_ID}
              onSuccess={responseGoogle}
              onFailure={responseGoogleFailed}
              render={(renderProps) => (
                <Button
                  type="primary"
                  block
                  className="px-2 rounded-xl bg-white text-gray-800"
                  onClick={renderProps.onClick}
                >
                  Sign in with Google
                </Button>
              )}
            />
          </div>
          <div className="text-center font-light text-xs tracking-wide text-white pt-2"></div>
          <div className="text-center font-light text-xs tracking-wide text-white">
            <Divider className="text-white font-light text-xs cursor-pointer">
              <Button
                onClick={() => setisSignUp(!isSignUp)}
                type="link"
                block
                className="px-2 rounded-xl "
              >
                {!isSignUp ? "Sign Up" : "Sign In"}
              </Button>
            </Divider>
          </div>
        </div>
      )}
    </Modal>}
    </>
  );
};

export default AuthModal;
