import { Divider, List, Menu } from "antd";
import Utils from "helper/Utils";
import React, { useEffect, useState } from "react";
import {
    ArrowBarDown,
  BookmarkHeart,
  Buildings,
  CalendarCheckFill,
  CardChecklist,
  ChatLeftQuoteFill,
  ChatRightText,
  EmojiHeartEyes,
  GeoAltFill,
  Heart,
  House,
  ListStars,
  Newspaper,
  People,
  PeopleFill,
  PersonFill,
  RssFill,
  Share,
  Shop,
  ShopWindow,
  Sliders,
  TextareaT,
} from "react-bootstrap-icons";
import { BsActivity } from "react-icons/bs";
import { HiOutlineMenu } from "react-icons/hi";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const ReLeftMenu = (props) => {
  const navigate = useNavigate()
  const brParams = useParams();
  const brLocation = useLocation();
  const user = Utils.getLocalInfo("userhash");
  const [selectedKeys, setselectedKeys] = useState(["/"])
  const [searchQuery, setsearchQuery] = useState("")
  useEffect(() => {
    setselectedKeys(brParams.state)
    setsearchQuery(brLocation.search)
    return () => {
      
    }
  }, [brLocation,brParams])
  

  const clickedMenu = (e) => {
    
   navigate("/re/"+e.key+""+searchQuery);
  };
  return (
    <div className="" >
   
      
      <div section-1 className=" p-2 w-100 overflow-y-scroll flex-1 search-left-menu">
        <Menu className="border-0 py-0" selectedKeys={selectedKeys} onSelect={clickedMenu}>
          <Menu.Item
            key={"listings"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full" ><Buildings size={18} /></div>}
          >
            <span>Listings</span>
          </Menu.Item>
          <Menu.Item
            key={"activities"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><BsActivity size={18} /></div>}
          >
            <span>Your Activities</span>
          </Menu.Item>
          <Menu.Item
            key={"own-listings"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><HiOutlineMenu size={18} /></div>}
          >
            <span>Your Listings</span>
          </Menu.Item>
          {/* <Menu.Item
            key={"creators"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><PersonFill size={18} /></div>}
          >
            <span>Creators</span>
          </Menu.Item>
          <Menu.Item
            key={"marketplace"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><ShopWindow size={18} /></div>}
          >
            <span>Web3 Marketplace</span>
          </Menu.Item>
          <Menu.Item
            key={"groups"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><PeopleFill size={18} /></div>}
          >
            <span>Communities</span>
          </Menu.Item>
          <Menu.Item
            key={"streets"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><CalendarCheckFill size={18} /></div>}
          >
            <span>Web3 Streets</span>
          </Menu.Item>
          <Menu.Item
            key={"places"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><GeoAltFill size={18} /></div>}
          >
            <span>Web3 Places</span>
          </Menu.Item>
          <Menu.Item
            key={"dating"}
            className="text-hl flex flex-row items-center border-l-2 py-4"
            icon={<div className="h-9 w-9 flex items-center justify-center bg-gray-300 rounded-full selected-icon" ><EmojiHeartEyes size={18} /></div>}
          >
            <span>Web3 Dating</span>
          </Menu.Item> */}
        </Menu>
      </div>
      <div className="py-9"></div>
    </div>
  );
};

export default ReLeftMenu;
