import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { VideoContext } from "app/VideoContext";
import LeftTabs from "./comps/LeftTabs";
import LeftPane from "./comps/LeftPane";
import { Row, Col, Spin, Divider, Button } from "antd";
import {Container} from "react-bootstrap";
import "./css/style.css";
import ApiService from "services/ApiService";
import EditorStage from "./editor/EditorStage";
import { useParams } from "react-router-dom";

const VideoReview = (props) => {
  const { showPreview, setactiveLefttab, activeLefttab,videoContent, setvideoContent } = useContext(
    VideoContext
  );
  const [loading, setloading] = useState(true)
  
  const brPrams = useParams();
  useEffect(() => {
    // effect
    getCampaign(brPrams.id)
    return () => {
      setvideoContent(null)
    }
  }, [brPrams.id])

  const getCampaign = async(id)=>{
    const details = await ApiService.PostApiCalls("campaign/get/"+id,{},true)
    
    if(details[0]){
      const activeCampaign =details[1]["campaign"];
      setvideoContent(activeCampaign)
      setloading(false)
    }
  }

  if(loading){
    return (
      <Container fluid="true" className="px-0 h-screen">
     <Row className="mx-0 h-full flex flex-col items-center">
        <Col className="p-0 d-flex align-items-center space-y-2 flex-col justify-content-center"
         lg={24}      
         md={24}
         sm={24} ><Spin />
         <span className="text-lg">Getting it ready...</span>
         </Col>
         
         </Row></Container>
         )
  } 
  return (
    <div className="page-container">
      <div className="content">
        <div className="h-full" id="left-tabs">
          <LeftTabs callback={setactiveLefttab} />
        </div>
        <div className="h-full" id="left-pane">
          <LeftPane active={activeLefttab} videoContent={videoContent} />
        </div>
        <div className="relative">
          <EditorStage />
        </div>
      </div>
    </div>
  );
};

export default VideoReview;
