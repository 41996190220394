import { Button, Checkbox, Dropdown, Input, Menu, Spin ,Select, Switch} from "antd";
import VisualDisplay from "components/visuals/VisualDisplay";
import FileUploader from "container/common/FileUploader";
import SocialIcon from "container/common/SocialIcon";
import Utils from "helper/Utils";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  CaretDownFill,
  Upload as UploadIcon,
  Image,
  PlusCircle,
  Trash,
  Coin,
} from "react-bootstrap-icons";
import { BsSlashCircle } from "react-icons/bs";
import Tags from "container/common/Tags";
import md5 from "md5";
import ApiService from "services/ApiService";
import MineHashList from "components/Reusable/MineHashList";
import { useTranslation } from 'react-i18next';
import { HASH_DEFAULT_CHARGE, HASH_DEFAULT_GASFEE } from "constants/hashkeys.constant";
import BuyHashCard from "container/common/BuyHashCard";

const keyword_extractor = require("keyword-extractor");
const { TextArea } = Input;
const { Option } = Select;
const AddNewPost = ({ showModalFirst, callback, profiles, user }) => {
  const { t } = useTranslation();

  const [show, setshow] = useState(showModalFirst);
  const [textLimit, settextLimit] = useState(Utils.getTextLimit("twitter"));
  const [remainigLimit, setremainigLimit] = useState(
    Utils.getTextLimit("twitter")
  );
  const [postInfo, setPostInfo] = useState({
    profiles: profiles,
    text: "",
    visual: null,
    scheduled: false,
    time: 0,
    keywords: [],
    
  });
  const [showUpload, setshowUpload] = useState(false);
  const [showKeywords, setshowKeywords] = useState(false);
  const [selectedOne, setselectedOne] = useState("all");
  const [selectedOneId, setselectedOneId] = useState("all");
  const [activeText, setactiveText] = useState("");
  const [loadingHashes, setloadingHashes] = useState(true);
  const [mintedKeyhash, setmintedKeyhash] = useState([]);
  const [userTokens, setuserTokens] = useState(0);
  const [userTokensOg, setuserTokensOg] = useState(0);
  const [ActiveImages, setActiveImages] = useState([]);
  const [checkedHash, setcheckedHash] = useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [processOn, setprocessOn] = useState(false);
  const [showCalendar, setshowCalendar] = useState(false);
  const [privacyMode, setprivacyMode] = useState(false);
  const [mitedWitingHashes, setmitedWitingHashes] = useState([])
  const [checkCounter, setcheckCounter] = useState(0)
  const [needMoreTokens, setneedMoreTokens] = useState(false)
  const [showBuyView, setshowBuyView] = useState(false)
  const [prceedBuy, setprceedBuy] = useState(true)
  const [buyValue, setbuyValue] = useState(0);
  const handleButtonClick = (e) => {};

  const handleMenuClick = async (e) => {
    if (postInfo.text.trim() === "") {
      Utils.showNotification(
        "error",
        "Content Missing",
        "Please write some text."
      );
      return false;
    }
    if (e.key === "1") {
      const newArr = _.filter(postInfo.profiles, function(a, i) {
        return a.removed !== true;// ? { ...a, removed: true } : a;
      });
      // console.log(mitedWitingHashes);
      const mintedHashIDs = _.map(mitedWitingHashes,"hashId")
      // console.log(onlyids);
      // return;
      let campaignPost = {
        qoute: postInfo["text"],
        all: postInfo["all"],
        image: [postInfo["visual"]],
        tags: postInfo["keywords"],
        postingTime: postInfo["time"],
        mentions: [],
        day: 0,
        author: "My Company",
        brandImage:user["workspace"][0]["brandImage"],
        brandDefaultTemplate: user["workspace"][0]["brandDefaultTemplate"],
        selectedSocialProfiles:_.map(newArr, 'id'),
        isVideoPost: false,
        videoId: false,
        campaignId: 0,
        wid: user["workspace"][0]["wid"],
        userId: user["workspace"][0]["uid"],
        speechTemplate: null,
        speechTemplateText: postInfo["text"],
        isSingleVideo:postInfo["visual"] && postInfo["visual"]["visualType"] === "video"?true:false,
        isSinglePost:true,
        linktext: "Explore more " + "https://hashsaga.com/beta/"+user["userName"],
        all: {
          text: postInfo["text"],
          tags: postInfo["keywords"],
          mentions: [],
          linktext: "Explore more " + "https://hashsaga.com/beta/"+user["userName"],
          textlength: 0,
        },
        singlePostInfo:postInfo,
        mitedWitingHashes:mintedHashIDs
      };
      
      const postInsert = await ApiService.PostApiCalls("campaign/create-single",{post:campaignPost},true)
      if(postInsert[0] && postInsert[1]["IsSuccessful"]){
        Utils.showNotification("success","Done","Posts has been created")
        callback(false);
        localStorage.removeItem("memeframe");
      }
    } else {
      setshowCalendar(true);
    }
  };
  const menu = (
    <Menu onClick={handleMenuClick} className="rounded-xl p-1 px-2">
      <Menu.Item key="1" className="mb-2 rounded-xl py-2">
        {t("Send Now")}
      </Menu.Item>
      <Menu.Item key="2" className="mb-2 rounded-xl py-2">
        {t("Schedule for later")}
      </Menu.Item>
    </Menu>
  );


  useEffect(() => {
    let rfs = Utils.filterArray(profiles, [
      "name",
      "id",
      "source",
      "fb-picture",
    ]);

    const ifTwitter = _.filter(rfs, function(obj) {
      return obj.source === "Twitter" ? true : false;
    });
    settextLimit(
      ifTwitter[0] ? Utils.getTextLimit("twitter") : Utils.getTextLimit("all")
    );
    // const feedprofile = {name:"HashSaga Feed","id":"hash-saga","source":"feed","fb-picture":user["profilePicture"]?user["profilePicture"]:"https://hs-container.s3.ap-south-1.amazonaws.com/hashsaga.png"}
    // rfs.push(feedprofile)
    setPostInfo({ ...postInfo, profiles: rfs });
    startCounter()
    return () => {};
  }, [profiles]);

  useEffect(() => {
    const memeframe = localStorage.getItem("memeframe");
    if(memeframe){
      const meme = JSON.parse(memeframe);
      
      
      setPostInfo({ ...postInfo, visual: meme });
    }
  
    return () => {
      
    }
  }, [])
  
  const startCounter = () =>{

  }
  const selectOne = (k, d) => {
    if (k === "all") {
      setselectedOne("all");
      setselectedOneId("all");
    } else {
      const limit = Utils.getTextLimit(d.source.toLowerCase());
      settextLimit(limit);
      const text = postInfo[d["id"]]
        ? postInfo[d["id"]]["text"]
        : postInfo["text"];
      setactiveText(text);
      setremainigLimit(limit - text.length);
      setselectedOne(d);
      setselectedOneId(d["id"]);
    }
  };
  const getKeywords = () => {
    setPostInfo({ ...postInfo, keywords: [] });
    try {
      const extraction_result = keyword_extractor.extract(postInfo.text, {
        language: "english",
        remove_digits: true,
        return_changed_case: true,
        remove_duplicates: true,
        return_max_ngrams: 3,
      });
      let keyadds = []
      _.map(extraction_result,(k)=>{
        const tmp = {
          value:k.replace(/\s/g,''),
          code:"721",
          copies:null
      }
        keyadds.push(tmp);
      })
      
      setPostInfo({ ...postInfo, keywords: keyadds });
    } catch (error) {
      console.log(postInfo[selectedOneId]);
    }
  };
  const selectAllTokenCode = (e) =>{
    if(e === "1155"){
      const newArr = _.map(postInfo["keywords"], function(a,k) {
        return {...a,code:"1155",copies:10};
      });
      setPostInfo({ ...postInfo, keywords: newArr });
    }
    else{
      const newArr = _.map(postInfo["keywords"], function(a,k) {
        return {...a,code:"721",copies:null};
      });
      console.log(newArr);
      setPostInfo({ ...postInfo, keywords: newArr });
    }
   
  }

const updateTagsAdded =(i,tag)=>{

  const newArr = _.map(postInfo["keywords"], function(a,k) {
    return k === i ? tag : a;
  });
  setPostInfo({ ...postInfo, keywords: newArr });
}
  const ToggleInList = (a, e, k) => {
    e.stopPropagation();
    let newArr;
    
    if (a === "remove") {
      newArr = _.map(postInfo.profiles, function(a, i) {
        return i === k ? { ...a, removed: true } : a;
      });
    } else {
      newArr = _.map(postInfo.profiles, function(a, i) {
        return i === k ? { ...a, removed: false } : a;
      });
    }
    const ifTwitter = _.filter(newArr, function(obj) {
      return obj.source === "Twitter" && !obj.removed ? true : false;
    });
    settextLimit(
      ifTwitter[0] ? Utils.getTextLimit("twitter") : Utils.getTextLimit("all")
    );
    
    setPostInfo({ ...postInfo, profiles: newArr });

    // alert(k)//
  };
  const toggleVisual = (act) => {
    if (act === "remove") {
      setPostInfo({ ...postInfo, visual: null });
    } else {
      setshowUpload(true);
    }
  };
  const sendPost = async () => {
    console.log(postInfo);
  };
  const createMenu = () => {
    return (
      <Menu
        contentClassName="rounded-lg mt-2 px-3"
        className="mt-2 rounded-lg px-2"
      >
        {postInfo.profiles.map((d, k) => (
          <Menu.Item
            disabled={!d.removed ? false : true}
            key={k}
            onClick={() => selectOne(k, d)}
            className="flex-row flex social-profile mb-2 p-2 rounded-md items-center"
          >
            <SocialIcon icon={d.source.toLowerCase()} />
            <span
              className=" flex-grow overflow-hidden px-2 overflow-ellipsis"
              style={{ maxWidth: "150px" }}
            >
              {d.name}
            </span>
            {!d.removed ? (
              <span
                onClick={(e) => ToggleInList("remove", e, k)}
                className="text-hs-pink flex items-center bg-gray-100 px-2 rounded-md cursor-pointer"
              >
                <Trash />
              </span>
            ) : (
              <span
                onClick={(e) => ToggleInList("add", e, k)}
                className=" flex items-center bg-gray-100 px-2 rounded-md cursor-pointer text-green-700"
              >
                <PlusCircle />
              </span>
            )}
          </Menu.Item>
        ))}
        <Menu.Item
          onClick={() => selectOne("all", null)}
          key="all"
          className="flex-row flex social-profile mb-2 p-2 bg-gray-100 rounded-md items-center"
        >
          <span>Edit All Profiles</span>
        </Menu.Item>
      </Menu>
    );
  };
  const ImageUploadCallback = (images) => {
    
    const image = Utils.filterArray(images, [
      "name",
      "uid",
      "type",
      "url",
      "size",
      "taskId",
      'visualType',"extension","assetData"
    ]);
    setPostInfo({ ...postInfo, visual: image[0] });
    setshowUpload(false);
    // setActiveImages(images)
  };

  const setPostText = (t) => {
    setPostInfo({
      ...postInfo,
      [selectedOneId]: { ...postInfo[selectedOneId], text: t },
      text: t,
    });
    setactiveText(t);
    setremainigLimit(textLimit - t.length);
  };
  const setNewTags = (n) => {
    setPostInfo({ ...postInfo, keywords: n });
  };
  const activateHashes = async () => {
    const hashKeys = [];
    postInfo.keywords.map((k) => {
      hashKeys.push(Utils.getHashId(k["value"], true, true));
    });
    const addedKeyHashes = await ApiService.PostApiCalls(
      "keyhashes/list",
      { hashKeys: hashKeys },
      true
    );
    if (addedKeyHashes[0] && addedKeyHashes[1]["IsSuccessful"]) {
      setmintedKeyhash(addedKeyHashes[1]["hash"]);
      setuserTokens(addedKeyHashes[1]["user"]["hashTokens"]);
      setuserTokensOg(addedKeyHashes[1]["user"]["hashTokens"]);
      setcheckedHash([])
      setCheckAll(false)
      setloadingHashes(false);
    } else {
      setloadingHashes(false);
    }

    setshowKeywords(true);
    setshowUpload(true);
  };
  const toggleSelectedHash = (id, coins) => {
    let hashes = checkedHash;
    if (hashes.includes(id)) {
      _.remove(hashes, function(n) {
        return n == id;
      });
    } else {
      hashes.push(id);
    }
    setcheckedHash(hashes);
  };
  const checkedAll = (e, id, t) => {
    let hashes = checkedHash;
    let leftToken = 0;
    if (!e) {
      _.remove(hashes, function(n) {
        return n == id;
      });
      setuserTokens(userTokens + t);
      leftToken = userTokens + t;

    } else {
      setuserTokens(userTokens - t);
      leftToken = userTokens - t;

      hashes.push(id);
    }
    if(leftToken < 0){
      setneedMoreTokens(true)
      setbuyValue(Math.abs(leftToken))
    }
    
    setcheckedHash(hashes);
  };
  const onCheckAllChange = (e) => {
    let tokens = userTokensOg;
    if (e.target.checked) {
      let hashUpdates = [];
      postInfo.keywords.map((k) => {
        const id = Utils.getHashId(k["value"], true, true);
        if (!mintedKeyhash.includes(Utils.getHashId(k["value"], true, true))) {
          hashUpdates.push(id);
          tokens = tokens - (HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE);
        }
      });
      if(tokens < 0){
        setneedMoreTokens(true)
        
        setbuyValue(Math.abs(tokens));

      }
      setcheckedHash(hashUpdates);
      setuserTokens(tokens);
    } else {
      setcheckedHash([]);
      setuserTokens(userTokensOg);
    }

    setCheckAll(e.target.checked);
  };
  const mintHashes = async (now) => {
    if(userTokens < 0){
      Utils.showNotification(
        "warning",
        "Oops!",
        "You do not have required hash tokens to process."
      );
    return;

    }
    let hashUpdates = [];
    postInfo.keywords.map((k) => {
      const id = Utils.getHashId(k["value"], true, true);
      if (checkedHash.includes(id)) {
        const hash = {
          title:k["value"],
          code:k["code"],
          copies:k["copies"],
          fraction:k["fractions"]?true:false,
          fractionValues:k["fractions"]?k["fractions"]:0,
          hashId: id,
          minted: now,
          tokensCharged:(HASH_DEFAULT_CHARGE+HASH_DEFAULT_GASFEE)
        };
        hashUpdates.push(hash);
      }
    });
    setprocessOn(true);
    setmitedWitingHashes([...mitedWitingHashes,...hashUpdates]);
    const minted = await ApiService.PostApiCalls(
      "keyhashes/mint",
      { hashKeys: hashUpdates, userToken: now ? userTokens : userTokensOg,status:"indraft" },
      true
    );
    if (minted[0] && minted[1]["IsSuccessful"]) {
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
      setshowUpload(false);
      setshowKeywords(false);
      setCheckAll(false);
      setuserTokensOg(now ? userTokens : userTokensOg);
      setcheckedHash([]);
    } else {
      Utils.showNotification(
        "success",
        "Done",
        "Hash have been sent for approval"
      );
    }
    setprocessOn(false);
  };

const updateBuyBack = (val)=>{
  
  setuserTokens(userTokens+val)
  setuserTokensOg(userTokensOg+val)
  setneedMoreTokens(false);
  setshowBuyView(false)
}
  // const menu = ;
  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          setshow(false);
          callback(false);
          localStorage.removeItem("memeframe");
        }}
        centered
        size="lg"
        
        contentClassName={`campaign-new-modal rounded-2xl ${showKeywords?"p-0":""}`}
        backdrop={"static"}
      >
        {!showUpload ? (
          <>
            {" "}
            <Modal.Header className="border-0">
              <div className="flex flex-row w-full space-x-4 items-center">
                {selectedOne !== "all" ? (
                  <div className="w-14 h-14 p-1 rounded-md bg-gray-50 relative flex flex-row flex-wrap">
                    <span className="absolute -left-0.5 -top-0.5">
                      <SocialIcon
                        icon={selectedOne.source.toLowerCase()}
                        round
                      />
                    </span>
                    <img
                      className="w-full h-full rounded-full"
                      src={selectedOne["fb-picture"]}
                      alt={selectedOne.name}
                    ></img>
                  </div>
                ) : (
                  <div className="w-14 h-14 p-1 rounded-md bg-gray-50 relative grid grid-flow-col grid-cols-2 grid-rows-2 social-icon-lists">
                    {postInfo.profiles.map((d, k) => (
                      <>
                        <img
                          key={3}
                          className={
                            k > 3
                              ? "rounded-full w-full h-full hidden"
                              : d.removed
                              ? "rounded-full w-full h-full hidden"
                              : "rounded-full w-full h-full"
                          }
                          src={d["fb-picture"]}
                          alt={d.name}
                        ></img>
                      </>
                    ))}
                  </div>
                )}
                <div className="p-2 flex flex-col space-x-4 flex-grow">
                  <div className="flex flex-row items-center justify-end space-x-2">
                    <Switch className='bg-hs-pink ' checkedChildren={t("Earning Mode")} onChange={(checked)=>setprivacyMode(!checked)} checked={!privacyMode} unCheckedChildren={t("Privacy Mode")} />
                  <div className="py-1 px-2 bg-red-300  text-xs align-self-end rounded-md text-red">
                    {remainigLimit}
                  </div></div>
                  <div className="py-2 flex flex-row space-x-4 px-0">
                    <div className="flex-grow">
                      <Dropdown overlay={createMenu} trigger={["click"]}>
                        <div className="flex flex-row items-center justify-between text-hs-light rounded-md bg-gray-100 p-2  cursor-pointer">
                          <span className="text-hs-dark">
                            {selectedOne === "all"
                              ? t("All Profiles")
                              : selectedOne.name}
                          </span>
                          <CaretDownFill />
                        </div>
                      </Dropdown>
                    </div>

                    <div className="flex flex-row items-center justify-between w-8 p-2  cursor-pointer"></div>
                    <div
                      onClick={() => toggleVisual("add")}
                      className={
                        !postInfo.visual
                          ? "flex flex-row items-center justify-center text-base w-10 text-hs-light rounded-md bg-gray-100 p-2  cursor-pointer"
                          : "flex flex-row items-center justify-center text-base w-10 text-hs-dark rounded-md bg-blue-100 p-2  cursor-pointer"
                      }
                    >
                      <Image />
                    </div>
                    <div
                      onClick={() => toggleVisual("remove")}
                      className={
                        postInfo.visual
                          ? "flex flex-row items-center justify-center text-base w-10 text-hs-light rounded-md bg-gray-100 p-2  cursor-pointer"
                          : "flex flex-row items-center justify-center text-base w-10 text-hs-dark rounded-md bg-blue-100 p-2  cursor-pointer"
                      }
                    >
                      <BsSlashCircle />
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column pt-0">
              <span className="fz-14 fw-600 pb-0">
                <TextArea
                  // showCount
                  maxLength={textLimit}
                  className="rounded-md border-0"
                  bordered={false}
                  placeholder={t("Type something")}
                  autoSize={{ minRows: 2, maxRows: 8 }}
                  value={activeText}
                  onChange={(e) => setPostText(e.target.value)}
                  // onBlur={()=>getKeywords()}
                />
              </span>
              {/* <div className="py-2"></div> */}
              <div className="p-2">
                <Tags
                  callback={setNewTags}
                  getKeywords={getKeywords}
                  tags={postInfo.keywords}
                />
              </div>
              <div className="py-2"></div>
              {postInfo.visual && <VisualDisplay visual={postInfo.visual} />}
            </Modal.Body>
            <Modal.Footer className="border-0 justify-start space-x-4">
              <>
                <Dropdown
                  // overlayClassName="p-1"
                  // size="large"

                  type="Div"
                  className="rounded-xl overflow-hidden bg-red-1 text-white border-0"
                  trigger={["click"]}
                  // onClick={handleButtonClick}
                  overlay={menu}
                >
                  <Button
                    type="primary"
                    // size="large"

                    className="px-3 brd-4 fw-700 mx-2 rounded-lg bg-hs-pink border-0 flex items-center"
                  >
                    {t("Send Post")}
                    <CaretDownFill size={20} className="ml-2" />
                  </Button>
                </Dropdown>
                {/* {postInfo.keywords.length > 0 && user.isAdmin && (
                  <Button
                    type="primary"
                    //  size="large"
                    onClick={() => activateHashes()}
                    className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
                  >
                    # Hash
                  </Button>
                )} */}
              
                {postInfo.keywords.length > 0 && user.selectedAppPlan.id === 5 && (
                  <Button
                    type="primary"
                    //  size="large"
                    onClick={() => activateHashes()}
                    className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
                  >
                    # Hash
                  </Button>
                )}
                <Button
                  // size="large"
                  onClick={() => {
                    setshow(false);
                    callback(false);
                    localStorage.removeItem("memeframe");
                  }}
                  className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  {t("Cancel")}
                </Button>
                
              </>
            </Modal.Footer>
          </>
        ) : showKeywords ? (
          <>
            <Modal.Header className="border-bottom p-2">
              <div className="flex flex-row w-full justify-between space-x-4 font-semibold items-center  p-2 ">
                <span> Manage Keywords</span>
                <span className>
                  {" "}
                  Your Tokens -{" "}
                  <span className="text-hs-pink"> {userTokens}</span>
                </span>
              </div>
            </Modal.Header>
           {showBuyView?<Modal.Body className="ct-image overflow-scroll max-h-100  p-3">
          <div className="flex flex-col space-y-3">
            
            
           <BuyHashCard backCallback={()=>{setshowBuyView(!showBuyView);setprceedBuy(true)}}   defaultValue={buyValue} tokens={buyValue} successCallback={updateBuyBack} />
          </div>


        </Modal.Body>: <Modal.Body className={`ct-image overflow-scroll p-3 ${showBuyView?"max-h-full":"max-h-96"}`}>
              {/* <div className="py-2"></div> */}
              {loadingHashes && (
                <div className="h-32 flex items-center justify-center">
                  <Spin />
                </div>
              )}
              <span className="font-semibold text-hs-blue">Minted Hash</span>
              {!loadingHashes && (
                <div className="py-2">
                  <div className="flex flex-col">
                    {postInfo.keywords.map(
                      (k, i) =>
                        mintedKeyhash.includes(
                          Utils.getHashId(k["value"], true, true)
                        ) && (
                         
                          <div
                            key={i}
                            className="flex flex-col p-2 border rounded-md text-hs-pink "
                          >
                            {`#${k["value"].replace(/ /g, "")}`}
                          </div>
                        )
                    )}
                  </div>
                </div>
              )}
              <div className="py-1"></div>
              <span className="font-semibold text-hs-blue">Avilable Hash</span>
              <Checkbox
                className="ml-3"
                indeterminate={false}
                onChange={onCheckAllChange}
                checked={checkAll}
              >
                Check all
              </Checkbox>
              <Select defaultValue="721" style={{ width: 120 }} size={"small"} onChange={selectAllTokenCode} >
            <Option value="721">721</Option>
            <Option value="1155">1155</Option>
            </Select>
              {!loadingHashes && (
                <div className="py-2">
                  <div className="flex flex-col">
                    {postInfo.keywords.map(
                      (k, i) =>
                        !mintedKeyhash.includes(
                          Utils.getHashId(k["value"], true, true)
                        ) && (
                          <MineHashList callback={updateTagsAdded} checkedHash={checkedHash} checkedAll={checkedAll}  key={i} id={i} k={k} />
                          
                        )
                    )}
                  </div>
                </div>
              )}
              <div className="py-2"></div>
            </Modal.Body>}
            {!showBuyView && <Modal.Footer className="border-top justify-end py-2 px-3 space-x-4">
              <>
                <Button
                  onClick={() => {
                    setshowUpload(false);
                    setshowKeywords(false);
                  }}
                  className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  Cancel
                </Button>
                {needMoreTokens && <Button
              type="primary"
              onClick={()=>{setshowBuyView(!showBuyView);}}
              className="px-3 brd-4 fw-700 mx-2 rounded-lg border-0 flex items-center"
            >
              Buy Hash
            </Button>}
                {checkedHash.length > 0 && (
                  <Button
                    loading={processOn}
                    onClick={() => {
                      mintHashes(true);
                    }}
                    className="px-3 brd-4 fw-700 mx-2 rounded-lg text-white bg-hs-pink  hover:border-hs-pink"
                  >
                    Mint Now
                  </Button>
                )}
                {/* {checkedHash.length > 0 && (
                  <Button
                    loading={processOn}
                    onClick={() => {
                      mintHashes(false);
                    }}
                    className="px-3 brd-4 fw-700 mx-2 rounded-lg text-hs-pink-dark border-hs-pink hover:text-hs-pink hover:border-hs-pink"
                  >
                    Mint Later
                  </Button>
                )} */}
              </>
            </Modal.Footer>}
          </>
        ) : (
          <>
            <Modal.Header className="border-0">
              <div className="flex flex-row w-full space-x-4 items-center">
                {t("Upload Image/Video")}
              </div>
            </Modal.Header>
            <Modal.Body className="ct-image">
              <div className="py-2"></div>
              <FileUploader
                prefix={"images"}
                showUploadList={false}
                multipleImages={false}
                callback={ImageUploadCallback}
                list={ActiveImages}
                limit={2}
                listType="none"
                view={
                  <div className="upload-imagebt w-19 h-16 p-2 rounded-md shadow flex flex-col items-center">
                    <UploadIcon color="#30B67C" />
                    <span className="ml-2 pt-1 fw-700">{t("Upload image")}</span>
                  </div>
                }
                viewUploading={
                  <div className="upload-imagebt">
                    <Spin size="small" spinning={true} />
                    <span className="ml-2 pt-1 fw-700">{t("Uploading...")}</span>{" "}
                  </div>
                }
              />
              <div className="py-2"></div>
            </Modal.Body>
            <Modal.Footer className="border-0 justify-start space-x-4">
              <>
                <Button
                  size="large"
                  onClick={() => {
                    setshowUpload(false);
                  }}
                  className="px-3 brd-4 fw-700 mx-2 rounded-lg hover:text-hs-pink hover:border-hs-pink"
                >
                  {t("Cancel")}
                </Button>
              </>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </div>
  );
};

export default AddNewPost;
