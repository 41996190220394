
import { Button } from "antd";
import { AuthContext } from "app/AuthProvider";
import Utils from "helper/Utils";
import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import {ShareSocial} from 'react-share-social'
import {
  CameraVideo,
  Heart,
  PatchCheckFill,
  Image,
  ClipboardX,
  Gear,
  Star,
  Plus,
  Dot,
  PersonPlus,
  Pencil,
  Camera,
  CameraFill,
} from "react-bootstrap-icons";
import ApiService from "services/ApiService";
import AvatarEditor from "container/common/AvatarEditor";
import CoverEditor from "container/common/CoverEditor";
import ProfileName from "components/ProfileName";

const GroupBanner = ({post}) => {
    const {authUser} = useContext(AuthContext)
    const [profile, setprofile] = useState(post)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [picModalVisible, setpicModalVisible] = useState(false);
    const [editType, seteditType] = useState("p")
    const shareLink = "https://hashsaga.com/groups/"+post.userName;
    const showModal = () => {
        
      setIsModalOpen(true);
    };
    const handleOk = (p) => {
      seteditType(p)
      setpicModalVisible(true);
    };
    const handleClose = () => {
      setIsModalOpen(false);
      setpicModalVisible(false);
    };
  
  
  const sendRequest = async()=>{
    const data = {
      uniqueId: post.uniqueId,
      name:post.name,
      status:profile.privacy === "Public"?"joined":"pending",
      member:{
          id:authUser.uniqueId,
          email:authUser.email,
          name:authUser.name,
      }
    };
    const req = await ApiService.PostApiCalls("group/join",data,true);
    if(req[0] && req[1]["IsSuccessful"]){
      setprofile(req[1]["group"])
    }
    Utils.showNotification("success",`${post.name}`,profile.privacy === "Public"?`You have joined the group`:`Your request to join group has been sent.`)
    
  }
  const updateProfile = (type,image) =>{
    setprofile({...profile,[type]:image})
  }
  const cancelRequest = async()=>{
    const data = {
      uniqueId: post.uniqueId,
      member:{
          id:authUser.uniqueId,
      }
    };
    const req = await ApiService.PostApiCalls("group/cancel",data,true);
    if(req[0] && req[1]["IsSuccessful"]){
      setprofile(req[1]["group"])
    }
    Utils.showNotification("success",`Your request has been cancelled.`)
    
    
  }
  // const viewCount = getRndInteger(50,400);
  const img =
    "https://hs-container.s3.ap-south-1.amazonaws.com/saga/user-assets/images/cJeBEMO6uM_u8h.jpg";
  return (
    <><div className="bg-white rounded-t-xl mb-0 relative  pb-7 flex flex-col ">
      <div
        className="relative inset-0 h-48  w-full rounded-xl bg-red-1 bg-center bg-cover flex flex-col items-start justify-end z-20"
        style={{
          backgroundImage: `url("${profile["coverPicture"]===""?img:profile["coverPicture"]}")`,
        }}
      >
        <div
          className="w-20 h-20 bg-red-400 rounded-full transform relative translate-x-10 translate-y-10 bg-center bg-cover border-2 border-red-500 overflow-visible"
          style={{
            backgroundImage: `url(${profile["profilePicture"]})`,
          }}
        >
          {authUser.uniqueId === profile.owner.id && <Button onClick={()=>handleOk("p")} className="rounded-full bg-gray-200 flex justify-around items-center font-normal absolute -right-2 -bottom-2 z-20" icon={<CameraFill className="text-hs-dark" size={18} />} ></Button>}

        </div>
        {authUser.uniqueId === profile.owner.id && <Button onClick={()=>handleOk("c")} className="rounded-md bg-gray-200 flex justify-around items-center font-normal absolute right-3 bottom-3" icon={<Pencil className="mr-2" />} >Edit</Button>}
      </div>
      <div className="py-2"></div>
      <div className="py-0 px-4 flex flex-row relative justify-start">
        <div className="ml-24"></div>
          <ProfileName classnames={"pl-2 ml-2 text-ht text-base font-semibold flex-grow flex flex-col"} verfied={profile["verfied"]} profileName= {profile.name} />
        
        <div className="flex-grow"></div>
       {/* { <div className="self-end flex flex-row items-center space-x-5">
          <div className="flex flex-row space-x-2 items-center">
            <Heart color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <Image color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
          <div className="flex flex-row space-x-2 items-center">
            <CameraVideo color="#F6327D" />
            <span>{getRndInteger(50,400)}</span>
          </div>
        </div>} */}
      </div>
      <div className="py-3"></div>
      <div className="text-hlt px-4 flex flex-row items-center justify-start">
        <div className="w-5"></div>
        <div className="flex flex-row items-center space-x-4 cursor-pointer">
          <div className="flex items-center space-x-1">@{profile.userName}</div>
          <span>|</span>
          <div className="flex items-center space-x-2">
            
          <span className="text-gray-400 flex items-center">{profile.privacy} Community<Dot /> {Utils.numberFormatter(profile.membersInfo[0]["active_count"],1) === "1" ?`${Utils.numberFormatter( profile.membersInfo[0]["active_count"],1)} member`:`${Utils.numberFormatter( profile.membersInfo[0]["active_count"],1)} members`} </span>
          </div>
          
        </div>
        <div className="flex-grow"></div>
        <div className="flex flex-row space-x-4 items-center">
          
          {
          profile.membersInfo[0]["user_member"] === 0 && profile.membersInfo[0]["user_pending"] === 0?
          <Button onClick={()=>sendRequest(profile.name)} className="bg-pink-600 rounded-md text-white flex justify-around items-center font-normal" icon={<PersonPlus size={19} className="font-semibold mr-2" />}>Join Community</Button>
          :
          profile.membersInfo[0]["user_member"] === 0 && profile.membersInfo[0]["user_pending"] === 1?
          <Button onClick={()=>cancelRequest(profile.name)} className="rounded-md bg-gray-200 flex justify-around items-center font-normal" >Cancel Request</Button>
          :
          <><Button onClick={showModal} className="rounded-2xl bg-primary border-0 flex items-center" icon={<Plus size={20} />} type="primary">
          Invite
        </Button>
        {authUser.uniqueId !== profile.owner.id && <Button onClick={()=>cancelRequest(profile.name)} className="rounded-md bg-gray-200 flex justify-around items-center font-normal" >Leave Community</Button>}
        </>
        }
        </div>
      </div>
      
    </div>
    <Modal show={isModalOpen} onHide={handleClose} dialogClassName="modal-25w">
    <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body></Modal.Body>
        
      </Modal>
      {/* Profile and Coverpic Modal */}
      <Modal show={picModalVisible} onHide={handleClose} dialogClassName={editType === "p"?"max-w-lg":"max-w-screen-md"}>
    <Modal.Header closeButton className="text-center justify-center" >
    <Modal.Title className="text-center h5 text-hs-dark flex-grow">Update profile picture</Modal.Title>
        </Modal.Header>
        <Modal.Body className="px-0">
          {editType === "p"?
          <AvatarEditor pic={profile.profilePicture} action="group/update" callback={updateProfile} gid={profile.uniqueId} modalCallback={handleClose} />
          :
          <CoverEditor pic={profile.coverPicture} action="group/update" callback={updateProfile} gid={profile.uniqueId} modalCallback={handleClose} />
          }
          
          </Modal.Body>
        
      </Modal>
   </>
  );
};

export default GroupBanner;
