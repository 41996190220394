import { Button, Card } from "antd";
import { AuthContext } from "app/AuthProvider";
import Utils from "helper/Utils";
import React, { useState } from "react";
import { useContext } from "react";
import { PersonPlus } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import ApiService from "services/ApiService";

const { Meta } = Card;

const GroupCard = ({ post }) => {
  const {authUser} = useContext(AuthContext)
  const [profile, setprofile] = useState(post)
  
  const sendRequest = async()=>{
    const data = {
      uniqueId: post.uniqueId,
      name:post.name,
      status:profile.privacy === "Public"?"joined":"pending",
      member:{
          id:authUser.uniqueId,
          email:authUser.email,
          name:authUser.name,
      }
    };
    const req = await ApiService.PostApiCalls("group/join",data,true);
    if(req[0] && req[1]["IsSuccessful"]){
      setprofile(req[1]["group"])
    }
    Utils.showNotification("success",`${post.name}`,profile.privacy === "Public"?`You have joined the group`:`Your request to join group has been sent.`)
    
  }
  const cancelRequest = async()=>{
    const data = {
      uniqueId: post.uniqueId,
      member:{
          id:authUser.uniqueId,
      }
    };
    const req = await ApiService.PostApiCalls("group/cancel",data,true);
    if(req[0] && req[1]["IsSuccessful"]){
      setprofile(req[1]["group"])
    }
    Utils.showNotification("success",`Your request has been cancelled.`)
    
    
  }
  return (
    <div className="flex flex-row border-b p-2 space-x-3 ">
      <Link to={`/groups/${profile.userName}`}><div
        className="bg-center w-24 h-24 bg-contain bg-no-repeat rounded-full bg-gray-100"
        style={{ backgroundImage: `url("${profile.profilePicture}")` }}
      ></div></Link>
      <div className="flex flex-col py-2 flex-grow">
        <Link to={`/groups/${profile.userName}`}><span className="font-semibold text-lg text-hs-dark">{profile.name}</span></Link>
        {profile.membersInfo && <span className="text-gray-400">{profile.privacy} {Utils.numberFormatter( profile.membersInfo[0]["active_count"],1)} members</span>}
        {!profile.membersInfo && <span className="text-gray-400">{profile.privacy}</span>}
      </div>
      {profile.membersInfo && <div className="flex items-end justify-end ">
        {
          profile.membersInfo[0]["user_member"] === 0 && profile.membersInfo[0]["user_pending"] === 0?
          <Button onClick={()=>sendRequest(profile.name)} className="bg-pink-600 rounded-md text-white flex justify-around items-center font-normal" icon={<PersonPlus size={19} className="font-semibold mr-2" />}>Join Community</Button>
          :
          profile.membersInfo[0]["user_member"] === 1 && profile.membersInfo[0]["user_pending"] === 0?
          <Link to={`/groups/${profile.userName}`}><Button className=" rounded-md bg-gray-200 flex justify-around items-center font-normal" >Visit</Button></Link>
          :
          <Button onClick={()=>cancelRequest(profile.name)} className="rounded-md bg-gray-200 flex justify-around items-center font-normal" >Cancel Request</Button>
        }
      {/* {!requested?<Button onClick={()=>sendRequest(post.name)} className="bg-pink-600 rounded-md text-white flex justify-around items-center font-normal" icon={<PersonPlus size={19} className="font-semibold mr-2" />}>Join Group</Button>
      :<span>Request Sent!</span>} */}
      </div>}
    </div>
  );
};

export default GroupCard;
