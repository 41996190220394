import React, { useEffect, useRef, useState } from 'react'
import { Stage, Layer, Text, Rect } from "react-konva";
import TextFrame from './TextFrame';
import ImageFrame from './ImageFrame';
import {Modal} from "react-bootstrap";
import { Button, Form, Input, message, Upload } from 'antd';
import ApiService from 'services/ApiService';
const MemeFrame = ({memeTemplate,memeImage,backIt,postmeme}) => {
  console.log(memeImage);
  
    const [form] = Form.useForm();
    const externalButtonRef = useRef(null);
    const [createdMeme, setcreatedMeme] = useState(null)
    const [sending, setsending] = useState(false)
    const formLayout = "vertical";
    const formItemLayout =
    formLayout === 'horizontal'
      ? {
          labelCol: {
            span: 4,
          },
          wrapperCol: {
            span: 14,
          },
        }
      : null;
  const buttonItemLayout =
    formLayout === 'horizontal'
      ? {
          wrapperCol: {
            span: 14,
            offset: 4,
          },
        }
      : null;
    useEffect(() => {
      
    console.log(memeImage);
    
      return () => {
        
      }
    }, [])
    const handleSubmit = async(values) => {
      setsending(true);
      const body = {templateId:memeImage.templateId,text0:values.toptext,text1:values.bottomtext}
      const createdmeme = await ApiService.PostApiCalls("/memes/create",body)
      if(createdmeme[1]["IsSuccessful"]){
        setcreatedMeme(createdmeme[1]["meme"])
      }
      else{
        message.error(createdmeme[1]["error"]);
      }
      setsending(false);

    };
  
    const handleExternalButtonClick = () => {
      // Programmatically submit the form
      form.submit();
    };
    const validateAtLeastOne = () => ({
      validator(_, value) {
        const field1 = form.getFieldValue("toptext");
        const field2 = form.getFieldValue("bottomtext");
  
        if (field1 || field2) {
          return Promise.resolve();
        }
        return Promise.reject(new Error("At least one is required!"));
      },
    });
    const handleFailed = (errorInfo) => {
      console.error("Validation Failed:", errorInfo);
      
    };
    const saveandpostmeme = ()=>{
      localStorage.setItem("memeframe",JSON.stringify(createdMeme));
      postmeme()
    }
  return (
    <> 
    {!createdMeme ?<div className='flex  w-full justify-center relative '>
      <div>
      <img src={memeImage.imglink} className={"w-40 h-40  p-1"} />
      </div>
       <div className='flex-1 px-3 flex-col'>
        <Form  {...formItemLayout}
      layout={formLayout}
      form={form}
      onFinish={handleSubmit}
      onFinishFailed={handleFailed}
      initialValues={{
        layout: formLayout,
      }}>
        <Form.Item label="Top Text" name="toptext"  rules={[validateAtLeastOne()]}>
        <Input placeholder="type here..." maxLength={120} showCount/>
      </Form.Item>
      <Form.Item label="Bottom Text" name="bottomtext"  rules={[validateAtLeastOne()]}>
        <Input placeholder="type here..." maxLength={120} showCount/>
      </Form.Item>
      
        
      
        </Form>
       </div>
      
    </div>:<div className='flex  w-full justify-center relative '>
      
      <img src={createdMeme.url} className={"w-full md:w-96 bg-gray-300  p-1 rounded"} />
      
       
      
    </div>}
    <Modal.Footer className="mt-4 px-2 w-full">
    <div className='flex w-full justify-between space-x-4'>
    <div className='flex-1'>
        <Button variant={"primary"} onClick={backIt} >
               Back
    </Button>
    </div>
    
 
    
    {!createdMeme?<Button type="primary" htmlType="submit" loading={sending} onClick={handleExternalButtonClick} ref={externalButtonRef} >Submit</Button>:
    <Button type="primary" size='middle' onClick={saveandpostmeme} >Post Meme</Button>
    }
      
    </div>
    </Modal.Footer></>
  )
}

export default MemeFrame