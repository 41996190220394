import { Pagination, Spin } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import ApiService from 'services/ApiService'

const MemesListing = ({category,callback}) => {
    const [loading, setloading] = useState(true);
    const [memesList, setmemesList] = useState([])
    const [memesFilters, setmemesFilters] = useState({q:category,currentPage:0,total:0})
    const [selectedCat, setselectedCat] = useState(null)
    const [selectedNFTKey, setselectedNFTKey] = useState(null)
    const [nftMedia, setnftMedia] = useState([])
    
    useEffect(() => {
        GetNFTs()
      
    
      return () => {
        
      }
    }, [category,memesFilters.currentPage])
    const GetNFTs = async()=>{
        let config = {
            method: 'get',
            maxBodyLength: Infinity,
            url: 'https://artequitys.com:4000/collection/listNftInCollection?collection_id=67571d2d65470615e0fc2a79',
            headers: { }
          };
          
          axios.request(config)
          .then((response) => {
            if(response.data["status"] === 200 && response.data["success"]){
                setnftMedia(response.data["data"])
            }
           
            GetMemes()
        

          })
          .catch((error) => {
            GetMemes()
          });
          
    }
    const GetMemes = async() =>{
        const listings = await ApiService.GetApiCalls("/memes/list",`q=${memesFilters.q}&page=${memesFilters.currentPage}`)
        
        setmemesList(listings[1]["listing"])
        setmemesFilters({...memesFilters,total:listings[1]["total"]})
        setloading(false)
        
    }
    const changePage = (e)=>{
        setselectedCat(null)
        setmemesFilters({...memesFilters,currentPage:e})
        
    }
    const processNFTMedia = (key)=>{
        setselectedNFTKey(key);
        setselectedCat(null);
        callback({imglink:nftMedia[key]["nft_data"][0]["nft_media"][0]})
    }
  return (
    loading ?<div className='py-4 flex flex-col items-center justify-center'><Spin loading={loading} >
    
    </Spin><div className='mx-auto py-5'>Searching best images..</div></div>
    : <><h6>Select One</h6>
    <div className="d-flex align-items-center justify-around flex-row  flex-wrap mt-4 h-96 overflow-y-scroll">
    {
                nftMedia.slice(0,10).map((meme,key) => {
                    return (
                        <div key={key} onClick={()=>processNFTMedia(key)} className={selectedNFTKey === key?"cursor-pointer px-2 w-36 d-flex align-items-center flex-column justify-content-center ":"cursor-pointer px-2 w-36 d-flex align-items-center flex-column justify-content-center "}>
                            <div className="d-flex align-items-center flex-column justify-content-center">
                                <img src={meme["nft_data"][0]["nft_media"][0]} className={selectedNFTKey === key?"w-36 h-36  p-1 bg-red-400":"w-36 h-36  p-1"} />
                                <span className={selectedNFTKey === key?"text-red-400 font-semibold text-sm  pb-6":"text-gray-70 font-semibold text-sm opacity-60 pb-6"}>{meme["nft_data"][0]["nft_name"]}</span>
                            </div>
                        </div>
                    )
                })
               }
         {
                memesList.map((meme,key) => {
                    return (
                        <div key={key} onClick={()=>{setselectedCat(key);setselectedNFTKey(null);callback(memesList[key])}} className={selectedCat === key?"cursor-pointer px-2 w-36 d-flex align-items-center flex-column justify-content-center ":"cursor-pointer px-2 w-36 d-flex align-items-center flex-column justify-content-center "}>
                            <div className="d-flex align-items-center flex-column justify-content-center">
                                <img src={meme.imglink} className={selectedCat === key?"w-36 h-36  p-1 bg-red-400":"w-36 h-36  p-1"} />
                                <span className={selectedCat === key?"text-red-400 font-semibold text-sm  pb-6":"text-gray-70 font-semibold text-sm opacity-60 pb-6"}>{meme.title}</span>
                            </div>
                        </div>
                    )
                })
               }
    </div>
    <div className='py-2'>
    <Pagination defaultCurrent={memesFilters.currentPage === 0?1:memesFilters.currentPage} onChange={changePage} showQuickJumper={false} showSizeChanger={false} total={memesFilters.total} />
    </div>
    </>
  )
}

export default MemesListing