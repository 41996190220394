import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import hashlogo from "assets/images/hash-logo.png";
import { Switch,Menu, Dropdown, Col, Button, Form, message,Input } from 'antd';
import { useTranslation } from 'react-i18next';
import { Translate } from "react-bootstrap-icons";
import BluLogo from 'assets/BleLogo';
import { useEffect } from 'react';
import ApiService from 'services/ApiService';
import axios from 'axios';
import Utils from 'helper/Utils';
import { Modal } from 'react-bootstrap';
import { BsAndroid2 } from 'react-icons/bs';

// import Logo from "assets/images/Logo@2x.png"
const NavBarHome = () => {
  const [privacyMode, setprivacyMode] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalOption, setmodalOption] = useState("share");
  const [sendingAppMail, setsendingAppMail] = useState(false)
  const { t } = useTranslation();
  const changeLocale = (locale) =>{
    localStorage.setItem('appLocale',locale);

    window.location.reload();


}
useEffect(() => {
  const modein = localStorage.getItem("appUsageMode")
  if(modein){
    setprivacyMode(modein === "privacy"?true:false)
  }

  return () => {
    
  }
}, [])

const showModal = () => {
  setIsModalOpen(true);
};
const handleOk = () => {
  setIsModalOpen(false);
};
const handleCancel = () => {
  setIsModalOpen(false);
  setmodalOption("share");
};
const getApp = async() =>{
  setmodalOption("app");
  showModal()
}
const onFinish = async(values)=>{
  
  setsendingAppMail(true)
  const emailed = await ApiService.PostApiCalls("general/download/app",{...values})
  message.success("Email has been sent successfully.");
  handleCancel();
  setsendingAppMail(false)

  
}
const onFinishFailed = (errorInfo) => {
  
};
const updatePrivacyMode = (mode) =>{
  
  localStorage.setItem('appUsageMode',mode?"privacy":"earning");
  setprivacyMode(mode)
}
  const usermenu =  (
    <Menu className="mt-0">
         <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("en")} >
      <span className="ml-3" >English</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("ja")} >
      <span className="ml-3" >Japanese</span>  
      </Menu.Item>
      <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("de")} >
      <span className="ml-3" >Deutsch</span>  
      </Menu.Item> <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("fr")} >
      <span className="ml-3" >Français</span>  
      </Menu.Item> 
       <Menu.Item className="p-2 pr-4" onClick={()=>changeLocale("hi")} >
      <span className="ml-3" >हिन्दी</span>  
      </Menu.Item>
    </Menu>
  );
    return (
      <>
        <nav className="flex fixed w-full items-center justify-between flex-wrap px-2 pt-2 home-bg z-20">
          
  <Col className="flex items-center text-center  flex-shrink-0 text-white" xs={12} sm={12} md={12} lg={12} xl={12}>
  
  <Link to={"/"}><span className="text-h text-2xl p-0 font-semibold flex items-center space-x-2">
            {/* <BluLogo /> */}
            <img src={hashlogo} className="w-8" />
            <span className="text-white">HashSaga</span>
          </span></Link> 
  </Col>
  
  <Col className="w-full flex justify-end items-end"  xs={12} sm={12} md={12} lg={12} xl={12}>
  <div className='fixed bottom-0 w-full left-0 p-3 text-center bg-gray-200 visible md:hidden'>
  <Button onClick={getApp} className='rounded mr-3 bg-hs-blue  border-hs-dark' type='primary' >...Download BluMe App..</Button>
  </div>
    <div className="flex items-center text-sm lg:flex-grow pr-4 justify-end">
      <Button onClick={getApp} className='rounded mr-3 bg-hs-blue hidden md:flex border-hs-dark items-center space-x-2' type='primary' icon={<BsAndroid2 />} >Download BluMe App</Button>
    <Switch className='bg-hs-pink ' checkedChildren={t("Earning Mode")} onChange={(checked)=>updatePrivacyMode(!checked)} checked={!privacyMode} unCheckedChildren={t("Privacy Mode")} />
      {/* <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
        Docs
      </a>
      <a href="#responsive-header" className="block mt-4 lg:inline-block lg:mt-0 text-teal-200 hover:text-white mr-4">
        Examples
      </a>*/}
      <a href="#responsive-header" className="block  lg:inline-block lg:mt-0 text-teal-200 ml-2">
      
      <Dropdown overlay={usermenu} placement="bottomRight" trigger={['click']}>
        {/* <User color="#ffffff" /> */}
        <div className='flex bg-light rounded-full items-center justify-center w-8 h-8' ><Translate /></div>
        </Dropdown> 
      </a> 
      
    </div>
    <div>
      
    </div>
  </Col>
</nav>
<Modal show={isModalOpen} onHide={handleCancel} dialogClassName="modal-25w">
    <Modal.Header closeButton>
          
        </Modal.Header>
        <Modal.Body className="p-0">
  {modalOption === "share"? <p></p>:
   <p className="p-3">
    <span className="font-semibold text-lg">Download BluMe android app to earn HASHS coins for every second of activity you do on HASHSAGA Ecosystem. Share email to receive download link.</span>
   <span>
   <Form
                name="basic"
                layout="vertical"
                wrapperCol={{ span: 24 }}
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                className="text-white"
              >
 <Form.Item
                  label={t("Email")}
                  className="py-2"
                  name="email"
                  rules={[
                    {
                      type: 'email',
                      message: 'The input is not valid E-mail!',
                    },
                    {
                      required: true,
                      message: 'Please input your E-mail!',
                    },
                  ]}
                >
                  <Input
                    className="p-2 rounded-xl"
                    placeholder="your@email.com"
                  />
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 0, span: 24 }}>
                  <Button
                    type="primary"
                    loading={sendingAppMail }
                    htmlType="submit"
                    className="px-2 rounded-xl"
                  >
                    {t("Get App Link")}
                  </Button>
                </Form.Item>
    </Form>
   </span>
   </p>
   
   }
   </Modal.Body>
 </Modal>

</>
    )
}

export default NavBarHome
